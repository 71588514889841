import React, { useEffect, useState } from 'react'
import { PortalUserRole, Url } from '../../EnvoirnmentSetup/APIs'
import nodatafound from '../../assets/nodata.png'
import breadCrumbArrow from '../../assets/backArrowGray.svg'
import { useNavigate } from 'react-router-dom'
import { useSelectedCardContext } from '../../Context/Context'
import { ModuleCards } from '../../constants/ModuleCards'
import Modal from "react-modal";
import Lottie from "lottie-react";
import loader from "../../../src/assets/PANLoader.json";
import moment from "moment";


const PurchaseHistory = () => {
    const { selectedCard, setSelectedCard } = useSelectedCardContext();
    const [fetching, setFetching] = useState(false);
    const [list,setList] = useState([])
    const navigate = useNavigate()
    useEffect(() => {
        setSelectedCard(ModuleCards.PurchaseHistory)
        getAllPurchasedLicenses()
    }, [])

    const loaderstyle = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            // marginRight: '-50%',
            transform: "translate(-50%, -50%)",
            minWidth: "500px",
            borderRadius: "25px",
            border: "none",
            padding: "10px",
            backgroundColor: "transparent ",
        },
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.7)",
        },
    };

    const getAllPurchasedLicenses = () => {
        setFetching(true)
        let token = sessionStorage.getItem('token')
        fetch(Url.purchasedLicenses, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization':"Bearer " + token,
            }
        })
            .then((resp) => resp.json())
            .then((res) => {
                setFetching(false)
                setList(res?.DATA)
            })
            .catch((e) => {
                console.log(e);
                setFetching(false)
            })
    }

    return (
        <>
            {PortalUserRole == "ROLE_MANAGER" ?
                <div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', margin: ' 5px 13px 0', marginTop: 0 }}>
                        <p className="breadCrumbDiv1p" style={{ marginBottom: "5px" }}>Purchase History</p>
                        <ul className="breadCrumb">
                            <li className="breadcrumbDiv2"><img className="breadcrumbDiv2Img" src={breadCrumbArrow} alt="" />
                                <span onClick={() => navigate(-1)} style={{ color: "#007749" }}>Purchase History</span>
                            </li>
                        </ul>
                    </div>

                    <div className="EditJobOuterDiv" style={{width:"97%",margin:"0 auto"}}>
          <div className="EditJobTableSubDiv" style={{height:"70vh"}}>
          {list.length!=0?<table className="tableStyleMyJobs">
            <thead style={{position: "sticky", top:"0",height:"30px"}}>
                <tr
                  style={{backgroundColor: '#007749', color: '#fff' }}
                >
                  <th
                    colspan="1"
                    role="columnheader"                   
                    style={{borderTopLeftRadius:"10px",width:"3%",borderRight:"0.5px solid gray"}}
                    
                  >
                    <span>#</span>
                  </th>
                  
                  <th
                    colspan="1"
                    role="columnheader"                   
                    style={{ borderRight:" 0.5px solid gray", width:"20%"}}
                  >
                    <span>License Type</span>
                  </th>

                  {/* <th
                    colspan="1"
                    role="columnheader"                   
                    style={{borderRight:" 0.5px solid gray", width:"17%"}}
                  >
                    <span>Purchase Type</span>
                  </th> */}

                  <th
                    colspan="1"
                    role="columnheader"                   
                    style={{borderRight:" 0.5px solid gray", width:"5%"}}
                  >
                    <span>Quantity</span>
                  </th>

                  <th
                    colspan="1"
                    role="columnheader"                    
                    style={{borderRight:" 0.5px solid gray", width:"10%"}}
                  >
                    <span>Cost</span>
                  </th>

                  <th
                    colspan="1"
                    role="columnheader"                   
                    style={{borderRight:" 0.5px solid gray", width:"10%"}}
                  >
                    <span>Purchase Date</span>
                  </th>

                  <th
                    colspan="1"
                    role="columnheader"                   
                    style={{borderRight:" 0.5px solid gray", width:"10%"}}
                  >
                    <span>Expiry Date</span>
                  </th>
                  
                  <th
                    colspan="1"
                    role="columnheader"                   
                    style={{borderRight:" 0.5px solid gray", width:"10%"}}
                  >
                    <span>Payment Id</span>
                  </th>

                  {/* <th
                    colspan="1"
                    role="columnheader"                   
                    style={{borderTopRightRadius:"10px", width:"10%"}}
                  >
                    <span>Status</span>
                  </th> */}

                </tr>
            </thead>
            <tbody style={{borderBottom:"0.5px solid gray"}}>
              {
                list?.map((item,ind)=>{
                    return(
                        <tr key={ind} style={{backgroundColor:ind%2==0?"#C1E7D8":"#ffff"}}>
                            <td className='UMtbodyTd'><span style={{display:"flex",justifyContent:"center",alignItems:"center"}}>{ind + 1}</span></td>
                            <td className='UMtbodyTd'><span className='UMtbodySpan' >{item?.licenseType?.toUpperCase() == 'JOB_BUNDLE'? 'No. Of Jobs':item?.licenseType?.toUpperCase() == "COMPANY"? "Company Licenses":"User Licenses"}</span></td>
                            <td className='UMtbodyTd'><span className='UMtbodySpan'>{!!item?.quantity? `${item?.quantity}` : 'NA'}</span></td>
                            <td className='UMtbodyTd'><span className='UMtbodySpan'>{!!item?.cost? `₹${item?.cost}` : 'FREE'}</span></td>
                            <td className='UMtbodyTd'><span className='UMtbodySpan'>{moment(item?.purchasedDate).format('DD-MM-YYYY')}</span></td>
                            <td className='UMtbodyTd'><span className='UMtbodySpan'>{moment(item?.expiryDate).format('DD-MM-YYYY')}</span></td>
                            <td className='UMtbodyTd'><span className='UMtbodySpan' style={{color:item?.paymentType?.toLowerCase() == "free"? "black": !!item?.razorpayPaymentId? "black" : "red"}}>{item?.paymentType?.toLowerCase() == "free"? "NA" : !!item?.razorpayPaymentId ? item?.razorpayPaymentId : "PAYMENT FAIL"}</span></td>
                            {/* <td className='UMtbodyTd'><span className='UMtbodySpan' style={{color:item?.purchaseStatus?.toLowerCase() == "fail"? "red": item?.purchaseStatus.toLowerCase() == "success"?"green":"black",fontWeight:500}}>{item?.purchaseStatus}</span></td> */}
                        </tr>
                    )
                })
              }
              
            </tbody>
          </table>
          :
          <div className="noNotificationMsg" style={{height:"70vh"}}>
            <img
              src={nodatafound}
              className="nodataImage"
            />
            <p>No Data Available.</p>
          </div>
          }
          </div>
          </div>

                    <Modal
                        isOpen={fetching}
                        ariaHideApp={false}
                        style={loaderstyle}
                        contentLabel="Example Modal"
                        overlayClassName="Overlay"
                    >
                        <Lottie
                            animationData={loader}
                            loop={true}
                            style={{ width: "100px", height: "100px", margin: "0px auto" }}
                        />
                    </Modal>
                </div>
                :
                <div style={{ width: "100%", height: "85vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <img src={nodatafound} style={{ width: "20%" }} />
                    <span>Oops! No Page Found</span>
                </div>
            }
        </>
    )
}

export default PurchaseHistory