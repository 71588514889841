import React, { useEffect, useState } from 'react'
import './JobPostingPerRecruiter.css'
import editIcon from '../../../assets/edit.png'
import deleteIcon from '../../../assets/delete.png'
import Modal from "react-modal";
import userProfile from '../../../assets/userProfile.svg'
import searchIcon from '../../../assets/searchLogo.svg'
import { ComponentAndScreenConstant } from '../../../constants/componentAndScreenConstant';
import { PortalUserRole, Url } from '../../../EnvoirnmentSetup/APIs';
import { emailValidationForJobPoster, field, onlyAlphabets, onlyNumber } from '../../../Validation/Validation';
import Lottie from "lottie-react";
import Oops from "../../../assets/Opps.json";
import completed from "../../../assets/Success.json";
import nodata from '../../../assets/nodata.png'
import loader from "../../../assets/PANLoader.json";
import { useSelectedCardContext } from '../../../Context/Context';
import deleteIcon2 from '../../../assets/deleteIcon.svg'
import closeIcon from '../../../assets/closeIcon.svg'
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import nodatafound from '../../../assets/nodata.png'

const JobPostingPerRecruiter = () => {
  const [editUMModal, setEditUMModal] = useState(false)
  const [editUser,setEditUser] = useState(false)
  const [countryCode, setCountryCode] = useState([])
  const [popupMsg, setPopupMsg] = useState("")
  const [errPopup1, setErrPopup1] = useState(false)
  const [successPopup,setSuccessPopup] = useState(false)
  const [newUserDetails, setNewUserDetails] = useState({fullName: field, cntryCode: "249", mno: field, email: field})
  const [teamList, setTeamList] = useState([])
  const [searchVal,setSearchVal] = useState("")
  const [fetching, setFetching] = useState(false);
  const { selectedCard, setSelectedCard } = useSelectedCardContext();
  const [deletemodalIsOpen,setDeletemodalIsOpen] = useState(false)
  const [userIdForDelete,setUserIdForDelete] = useState()
  const [fetchAgain,setFetchAgain] = useState(false)
  const [editUserId,setEditUserID] = useState("")
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [dropdownList,setDropdownList] = useState([])
  const [dropdownVal,setDropdownVal] = useState("")
  const [dropdownVal2,setDropdownVal2] = useState(7)
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "40%",
      borderRadius: "25px",
      padding: "10px",
    },
  };

 
  const loaderstyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      // marginRight: '-50%',
      transform: "translate(-50%, -50%)",
      minWidth: "500px",
      borderRadius: "25px",
      border: "none",
      padding: "10px",
      backgroundColor: "transparent ",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
  };

  useEffect(() => {
    getAllDropdownList()
    setSelectedCard(7)
  }, [fetchAgain])
  const navigate = useNavigate()
  const getAllDropdownList = () => {
    try{
      setFetching(true)
      fetch(Url.JPRSdropdown,{
        method:"GET",
        headers:{
          "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token")
        }
      })
      .then((resp)=>resp.json())
      .then((res)=>{
        console.log("dropdown api res",res);
        if(res?.SUCCESS==true){
          setFetching(false)
          setDropdownList(res?.DATA)
          setDropdownVal(res?.DATA[0].userId)
          getDatabyRecruiterID(res?.DATA[0].userId, 7)

        }else{
          setFetching(false)
        }
      }).catch((e)=>{
        console.log(e);
        setFetching(false)
      })
    }
    catch(e){
      setFetching(false)
      console.log(e);
    }
  }


 

 



  const getDatabyRecruiterID = (recruiterUserId, noOfDays) => {
    console.log({recruiterUserId, noOfDays})
    try {
      let token = sessionStorage.getItem("token");

      fetch(
        Url?.getJobPostingperRecruiter
          .replace("{userId}", recruiterUserId)
          .replace("{days}", noOfDays),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
        .then((response) => response.json())
        .then((res) => {
          console.log("reportdata" ,res );
          if (res.SUCCESS) {

            setTeamList(res?.DATA)
            setFetching(false)
          }
        });
    } catch (error) {
      console.log("Experience", error);
    }
  };

  // const handleTotalPostedJobs = (postedDate) => {
  //   let newfromDate= new Date(postedDate);
  //   newfromDate =  newfromDate.setHours(0, 0, 1, 0)
  //   let newtoDate= new Date(postedDate);
  //   newtoDate =  newtoDate.setHours(23, 59, 59, 999)
  //   navigate('/dashboard/management/alljobs',{state:{fromDate:new Date(newfromDate), toDate: new Date(newtoDate)}})
  // }
  return (
    <>{PortalUserRole == "ROLE_MANAGER"?<div>
       <div className="appcntRepoFixedContent">
        <div className="applcntRepoOuterDiv" style={{ marginTop: "5px" }}>
          <p style={{ color: '#007749', fontSize: '1.5rem', marginTop: '15px',marginBottom:"5px" }}>Individual Recruiter's Report</p>
        </div> 
       </div>

       <div className='JPRSdropdownDiv'>
        <select onChange={(e)=>{setDropdownVal(e?.target?.value)
              getDatabyRecruiterID(e?.target?.value, dropdownVal2)
            } }>
           {
            dropdownList?.map((item,ind)=>{
              return(
                <option value={item?.userId}>{item?.fullName}</option>
              )
            })
           }
        </select>
        <select value={dropdownVal2} onChange={(e)=>{
         setDropdownVal2(e?.target?.value)
         getDatabyRecruiterID(dropdownVal, e?.target?.value)
         }}>
          <option value={7}>Last 7 days</option>
          <option  value={30}>Last 30 days</option>
        </select>
       </div>
    
      <div className="EditJobOuterDiv" style={{width:"97%",margin:"0 auto"}}>
          <div className="EditJobTableSubDiv" style={{height:dropdownVal2==7 && "47vh"}}>
          {teamList.length!=0?<table className="tableStyleMyJobs">
            <thead style={{position: "sticky", top:"0",height:"30px"}}>
                <tr
                  style={{backgroundColor: '#007749', color: '#fff' }}
                >
                  <th
                    colspan="1"
                    role="columnheader"                   
                    style={{borderTopLeftRadius:"10px",width:"5%",borderRight:"0.5px solid gray",backgroundColor: '#007749'}}
                    
                  >
                    <span>#</span>
                  </th>
                  
                  <th
                    colspan="1"
                    role="columnheader"                    
                    style={{ borderRight:" 0.5px solid gray", width:"35%",backgroundColor: '#007749'}}
                  >
                    <span>Date</span>
                  </th>
                  <th
                    colspan="1"
                    role="columnheader"                   
                    style={{borderRight:" 0.5px solid gray", width:"20%",backgroundColor: '#007749'}}
                  >
                    <span>Total Posted Jobs</span>
                  </th>
                  <th
                    colspan="1"
                    role="columnheader"                  
                    style={{borderRight:" 0.5px solid gray", width:"20%",backgroundColor: '#007749'}}
                  >
                    <span>Total Applicants Viewed</span>
                  </th>
                  <th
                    colspan="1"
                    role="columnheader"                   
                    style={{width:"20%",backgroundColor: '#007749',borderTopRightRadius:"10px"}}
                  >
                    <span>Total Applicants Applied</span>
                  </th>
                  
                </tr>
            </thead>
            <tbody>
              {
                teamList?.map((item,ind)=>{
                    return(
                        <tr style={{backgroundColor:ind%2==0?"#C1E7D8":"#ffff"}}>
                            <td className='JPRSRtbodyTd'><span style={{display:"flex",justifyContent:"center",alignItems:"center"}}>{ind + 1}</span></td>
                            <td className='JPRSRtbodyTd'><span className='JPRSRtbodySpan'>{moment(item?.date).format("DD-MMM-YYYY")}</span></td>
                            <td className='JPRSRtbodyTd'><span className='JPRSRtbodySpan' style={{justifyContent:"end",paddingRight:"20px"}}>{item?.jobCount}</span></td>
                            <td className='JPRSRtbodyTd'><span className='JPRSRtbodySpan' style={{justifyContent:"end",paddingRight:"20px"}}>{item?.totaJobViewd}</span></td>
                            <td className='JPRSRtbodyTd' style={{borderRight:"none"}}><span className='JPRSRtbodySpan' style={{justifyContent:"end",paddingRight:"20px"}}>{item?.totalJobApplied}</span></td>
                        </tr>
                    )
                })
              }
              
            </tbody>
          </table>
          :
          <div className="noNotificationMsg" style={{height:"50vh"}}>
            <img
              src={nodata}
              className="nodataImage"
            />
            <p>No Data Available.</p>
          </div>
          }
          </div>
          </div>
       {/*    <div style={{ display: "flex", justifyContent: "center" }} className="editJobpagination">
                <ComponentAndScreenConstant.TablePagination
                //   setPageNumber={setSelectedPageNumber}
                //   pagenumber={selectedPageNumber}
                //   totalpages={totalpages}
                //   isSavedPageNumberNeeded={true}
                />
              </div> */}
     
 

      <Modal
        isOpen={errPopup1}
        onRequestClose={() => setErrPopup1(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={() => setErrPopup1(false)} className="delAccModalCrossPara">×</p>
          </div>
        </div>
        <div style={{marginTop:-20}}>
          <Lottie
            animationData={Oops}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg" style={{padding:0}}>
          {popupMsg}
        </div>
        <div className="okBtnModal">
          <button onClick={() => { setErrPopup1(false) }}>OK</button>
        </div>
      </Modal>
      <Modal
        isOpen={successPopup}
        onRequestClose={() => setSuccessPopup(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
        shouldCloseOnOverlayClick={false}
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={() => setSuccessPopup(false)} className="delAccModalCrossPara">×</p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={completed}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg">
          {popupMsg}
        </div>
        <div className="okBtnModal">
          <button onClick={()=>setSuccessPopup(false)}>OK</button>
        </div>
      </Modal>

      <Modal
        isOpen={fetching}
        ariaHideApp={false}
        style={loaderstyle}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <Lottie
          animationData={loader}
          loop={true}
          style={{ width: "100px", height: "100px", margin: "0px auto" }}
        />
      </Modal>

     
    </div>:
    <div style={{width:"100%",height:"85vh",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
      <img src={nodatafound} style={{width:"20%"}}/>
      <span>Oops! No Page Found</span>
     </div>
}</>
  )
}

export default JobPostingPerRecruiter