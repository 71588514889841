export const ModuleCards = {
    Dashboard: 1,
    JobRepository: 2,
    ApplicantRepository: 3,
    profile: 4,
    Notification: 5,
    Management: 6,
    Reports: 7,
    CompanyJobs:10,
    CompanyManagement:11,
    PurchaseHistory:12
}