export const ApiProfile = {
    DevRecruiterV1: 'http://13.52.157.11:8080/quikhire-dev/api/v1/',
    IntRecruiterV1: 'http://13.52.157.11:8080/quikhire-int/api/v1/',
    UatRecruiterV1: 'http://13.52.157.11:8080/quikhire-uat/api/v1/',
    ProdRecruiterV1: 'https://recruiter.quikhire.ai/quikhire/api/v1/',

    DevLeaderV1: 'http://13.52.157.11:8080/quikhire-dev/api/v1/',
    IntLeaderV1: 'http://13.52.157.11:8080/quikhire-int/api/v1/',
    UatLeaderV1: 'http://13.52.157.11:8080/quikhire-uat/api/v1/',
    ProdLeaderV1: 'https://manager.quikhire.ai/quikhire/api/v1/',
    
    // DevAdminV1: 'http://13.52.157.11:8080/quikhire-dev/api/v1/',
    // IntAdminV1: 'http://13.52.157.11:8080/quikhire-int/api/v1/',
    // UatAdminV1: 'http://13.52.157.11:8080/quikhire-uat/api/v1/',
    // ProdAdminV1: 'https://admin.quikhire.ai/quikhire/api/v1/',
}

export const WebProfile = {
    DevRecruiterV1: 'http://13.52.157.11:8080/quikhire_dev_recruiter/',
    IntRecruiterV1: 'http://13.52.157.11:8080/quikhire_int_recruiter/',
    UatRecruiterV1: 'http://13.52.157.11:8080/quikhire_uat_recruiter/',
    ProdRecruiterV1: 'https://recruiter.quikhire.ai/',

    DevLeaderV1: 'http://13.52.157.11:8080/quikhire_dev_manager/',
    IntLeaderV1: 'http://13.52.157.11:8080/quikhire_int_manager/',
    UatLeaderV1: 'http://13.52.157.11:8080/quikhire_uat_manager/',
    ProdLeaderV1: 'https://manager.quikhire.ai/',

    DevAdminV1: 'http://13.52.157.11:8080/quikhire_dev_admin/',
    IntAdminV1: 'http://13.52.157.11:8080/quikhire_int_admin/',
    UatAdminV1: 'http://13.52.157.11:8080/quikhire_uat_admin/',
    ProdAdminV1: 'https://admin.quikhire.ai/',
}

export const getActivProfile = (profile) => {
    switch (profile) {
        case 'DevRecruiterV1': sessionStorage.setItem("signupUrl", WebProfile.DevRecruiterV1);
            return (ApiProfile.DevRecruiterV1);
        case 'IntRecruiterV1': sessionStorage.setItem("signupUrl", WebProfile.IntRecruiterV1);
            return (ApiProfile.IntRecruiterV1);
        case 'UatRecruiterV1': sessionStorage.setItem("signupUrl", WebProfile.UatRecruiterV1);
            return (ApiProfile.UatRecruiterV1);
        case 'ProdRecruiterV1': sessionStorage.setItem("signupUrl", WebProfile.ProdRecruiterV1);
            return (ApiProfile.ProdRecruiterV1);
        case 'DevLeaderV1': sessionStorage.setItem("signupUrl", WebProfile.DevLeaderV1);
            return (ApiProfile.DevLeaderV1);
        case 'IntLeaderV1': sessionStorage.setItem("signupUrl", WebProfile.IntLeaderV1);
            return (ApiProfile.IntLeaderV1);
        case 'UatLeaderV1': sessionStorage.setItem("signupUrl", WebProfile.UatLeaderV1);
            return (ApiProfile.UatLeaderV1);
        case 'ProdLeaderV1': sessionStorage.setItem("signupUrl", WebProfile.ProdLeaderV1);
            return (ApiProfile.ProdLeaderV1);
        default: sessionStorage.setItem("signupUrl", WebProfile.DevRecruiterV1);
            return (ApiProfile.DevRecruiterV1);
    }
}

export const getWebProfileForDeepLinking = (profile) => {
    switch (profile) {
        case 'DevRecruiterV1': 
        return 'https://jobs.quikhire.ai/Jobdetails/{jobId1}'
        case 'IntRecruiterV1': 
        return 'https://jobs.quikhire.ai/Jobdetails/{jobId1}'
        case 'UatRecruiterV1': 
        return 'https://jobs.quikhire.ai/Jobdetails/{jobId1}'
        case 'ProdRecruiterV1': 
        return 'https://jobs.quikhire.ai/Jobdetails/{jobId1}'
        case 'DevLeaderV1': 
        return 'https://jobs.quikhire.ai/Jobdetails/{jobId1}'
        case 'IntLeaderV1': 
        return 'https://jobs.quikhire.ai/Jobdetails/{jobId1}'
        case 'UatLeaderV1': 
        return 'https://jobs.quikhire.ai/Jobdetails/{jobId1}'
        case 'ProdLeaderV1': 
        return 'https://jobs.quikhire.ai/Jobdetails/{jobId1}'

        default:
            return ;
    }
}


export const getBaseURLTonavigatetoLogin = (profile, role) => {
    let UrlData = new Promise((resolve, reject) => {
        switch (true) {

            
            case profile =="DevRecruiterV1"  || profile =="DevLeaderV1": 
            if(role=="ROLE_RECRUTER"){
                let dataURL = WebProfile.DevLeaderV1
                console.log({dataURL})
                resolve(dataURL); 
            }else if(role=="ROLE_MANAGER"){
                let dataURL = WebProfile.DevRecruiterV1
                console.log({dataURL})
                resolve(dataURL); 
            }else if(role=="ROLE_ADMIN"){
                let dataURL = WebProfile.DevAdminV1
                console.log({dataURL})
                resolve(dataURL); 
            }


            case profile == "IntRecruiterV1"  || profile == "IntLeaderV1": 
            if(role=="ROLE_RECRUTER"){
                let dataURL = WebProfile.IntRecruiterV1
                console.log({dataURL})
                resolve(dataURL); 
            }else if(role=="ROLE_MANAGER"){
                let dataURL = WebProfile.IntLeaderV1
                console.log({dataURL})
                resolve(dataURL); 
            }else if(role=="ROLE_ADMIN"){
                let dataURL = WebProfile.IntAdminV1
                console.log({dataURL})
                resolve(dataURL); 
            }


            case profile == "UatRecruiterV1"  || profile == "UatLeaderV1": 
            if(role=="ROLE_RECRUTER"){
                let dataURL = WebProfile.UatRecruiterV1
                console.log({dataURL})
                resolve(dataURL); 
            }else if(role=="ROLE_MANAGER"){
                let dataURL = WebProfile.UatLeaderV1
                console.log({dataURL})
                resolve(dataURL); 
            }else if(role=="ROLE_ADMIN"){
                let dataURL = WebProfile.UatAdminV1
                console.log({dataURL})
                resolve(dataURL); 
            }


            case profile == "ProdRecruiterV1"  || profile == "ProdLeaderV1": 
            if(role=="ROLE_RECRUTER"){
                let dataURL = WebProfile.ProdRecruiterV1
                console.log({dataURL})
                resolve(dataURL); 
            }else if(role=="ROLE_MANAGER"){
                let dataURL = WebProfile.ProdLeaderV1
                console.log({dataURL})
                resolve(dataURL); 
            }else if(role=="ROLE_ADMIN"){
                let dataURL = WebProfile.ProdAdminV1
                console.log({dataURL})
                resolve(dataURL); 
            }
        }
    })
console.log({UrlData, profile, role})
    return UrlData

}
