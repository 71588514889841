import React, { useContext } from "react";
import { createContext } from "react";

const AuthContext = createContext();
const SelectedCardContext = createContext();
const NotificationContext = createContext();
const SelectedSubMenuContext = createContext();
const SelectedSubMenuJobContext = createContext();
const ProfileDropdownContext = createContext();

const useAuthContext = () => {
    return useContext(AuthContext)
}

const useSelectedCardContext = () => {
    return useContext(SelectedCardContext)
}

const useNotificationContext = () => {
    return useContext(NotificationContext)
}

const useSelectedSubMenuContext = () => {
    return useContext(SelectedSubMenuContext)
}

const useSelectedSubMenuJobContext = () => {
    return useContext(SelectedSubMenuJobContext)
}

const useProfileDropdownContext = () => {
    return useContext(ProfileDropdownContext)
}

export {AuthContext, useAuthContext, SelectedCardContext, useSelectedCardContext, NotificationContext, useNotificationContext, SelectedSubMenuContext, useSelectedSubMenuContext, SelectedSubMenuJobContext,useSelectedSubMenuJobContext,ProfileDropdownContext,useProfileDropdownContext}