import React, { useState } from 'react'
import '../../screens/PosterDashboard/PosterDashboard.css'
import { ComponentAndScreenConstant } from '../../constants/componentAndScreenConstant'

const PosterDashboard = () => {
  const [navigateScreen, setNavigateScreen] = useState(1)

  return (
    <div className='pDashOuterDiv'>
      <div className='pDashSidebar'>
        <ComponentAndScreenConstant.Sidebar navigateScreen={navigateScreen} setNavigateScreen={setNavigateScreen}/>
      </div>
      <div className='pDashContent'>
        <div className='pDashHeader'>
            <ComponentAndScreenConstant.DashBoardHeader navigateScreen={navigateScreen}/>
        </div>
      </div>
    </div>
  )
}

export default PosterDashboard