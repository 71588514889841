import React, { useEffect, useState } from "react";
import "./App.css";
import { AuthContext, SelectedCardContext, NotificationContext, SelectedSubMenuContext, SelectedSubMenuJobContext, ProfileDropdownContext } from "./Context/Context";
import Navigator from "./Navigation/Navigator";
import { ComponentAndScreenConstant } from "./constants/componentAndScreenConstant";
import { Url } from "./EnvoirnmentSetup/APIs";
// import { SHA256 } from 'crypto-js';

import Modal from "react-modal";
import Lottie from "lottie-react";
import Oops from "./assets/Opps.json";
import completed from "./assets/Success.json";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState("");
  const [userDetails, setUserDetails] = useState({ loggedInRole: "", jobLicense: {}, companyLicense: {}, userLicense: {}, isActive: null });
  const [selectedCard, setSelectedCard] = useState(0);
  const [notificationCount, setNotificationCount] = useState(0);
  const [subMenu, setSubMenu] = useState(false)
  const [subMenuColor, setSubMenuColor] = useState(0)
  const [subMenuColorMyJobs, setSubMenuColorMyJobs] = useState(0)
  const [subMenuJob, setSubMenuJob] = useState(0)
  const [dropDownVisible, setDropDownVisible] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [AlertMessages, setAlertMessages] = useState({
    message: '',
    isWaring: false,
    isVisible: false,
  })

  useEffect(() => {
    if (sessionStorage.getItem('token')) {
      getUserDataname()
    }
  }, []);

  const setWarning = (message) => {
    setAlertMessages({
      message: message,
      isWaring: true,
      isVisible: true,
    })
  }

  const setAlert = (message) => {
    setAlertMessages({
      message: message,
      isWaring: false,
      isVisible: true,
    })
  }

  const resetAlertMessages = () => {
    setAlertMessages({
      message: '',
      isWaring: false,
      isVisible: false,
    });
  }

  const getUserDataname = () => {
    let token = sessionStorage.getItem("token");
    fetch(Url.getuser, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((resp) => resp.json())
      .then((res) => {

        setUserDetails({
          ...userDetails, loggedInRole: res?.DATA?.roles[0]?.name, isActive: res?.DATA?.licensed
        })

      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <AuthContext.Provider value={{ userDetails, setUserDetails }}>
        <SelectedCardContext.Provider value={{ selectedCard, setSelectedCard, setWarning, setAlert, isLoading, setIsLoading }}>
          <SelectedSubMenuContext.Provider value={{ subMenu, setSubMenu, subMenuColor, setSubMenuColor }}>
            <SelectedSubMenuJobContext.Provider value={{ subMenuJob, setSubMenuJob, subMenuColorMyJobs, setSubMenuColorMyJobs }}>
              <ProfileDropdownContext.Provider value={{ dropDownVisible, setDropDownVisible }}>
                <NotificationContext.Provider value={{ notificationCount, setNotificationCount }}>
                  <Navigator />
                </NotificationContext.Provider>
              </ProfileDropdownContext.Provider>
            </SelectedSubMenuJobContext.Provider>
          </SelectedSubMenuContext.Provider>
        </SelectedCardContext.Provider>
      </AuthContext.Provider>
      <Modal
        ariaHideApp={false}
        className={`modalStyle`}
        overlayClassName={'modalOverlayStyle AlertModal'}
        isOpen={AlertMessages.isVisible}
        onRequestClose={resetAlertMessages}
      >
        <div className='warningOrAlert'>
          <Lottie
            loop={true}
            animationData={AlertMessages?.isWaring ? Oops : completed}
            className="delAccModalErrLottie"
          />
          {AlertMessages?.message}
          <button onClick={resetAlertMessages}>OK</button>
        </div>
      </Modal>
      
      <Modal
        isOpen={isLoading}
        contentLabel="Example Modal"
        ariaHideApp={false}
        className={`modalStyle`}
        overlayClassName={'modalOverlayStyle AlertModal LodarModal'}
      >
        <Lottie
          animationData={require('./assets/PANLoader.json')}
          loop={true}
          style={{ width: "100px", height: "100px", margin: "0px auto" }}
        />
      </Modal>
    </>
  );
}

export default App;
