import React, { useEffect, useRef, useState } from "react";
import "./SelectPicker.css";
const SelectPicker = ({
  label,
  isRequired,
  labelStyle = {},
  placeholderText = "",
  value,
  onTextChange,
  ParentFieldWrapper={},
  required,
  errormsg,
  onChange,
  data = [],
  defaultValueToDisplay="",
  rstSelector
}) => {
  const selectRef = useRef(null);


  useEffect(()=>{
    handleResetClick()
   
  },[rstSelector])
  



  const handleResetClick = () => {
    // Set the selected value to the default value
    if (selectRef.current) {
      selectRef.current.value = '';
    }
  };

  return (
    <div style={{width:"100%"}}>
      {label ? (
        <label className="defaultLabelStyle" style={labelStyle}>
          {label}
          {isRequired ? (
            <span style={{ color: "#BC0000", fontWeight: "bold" }}>{"*"}</span>
          ) : (
            <></>
          )}
        </label>
      ) : (
        <></>
      )}
     <div className='inputBoxContainer'>
          <select style={{color : !(!!value) && "gray" }}  ref={selectRef} onChange={(e) => onChange(e)} className='inputselector'>
            <option style={{display: 'none', color:"red"}} className='optionStyles' value={""}  selected= {!!value ? false : true} disabled >{defaultValueToDisplay}</option>
            {data?.map((item, index) => {
              return (
                <option className='optionStyles' key={index} value={JSON.stringify(item)} selected= {value==item.id ? true : false}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>  
    </div>
  );
};

export default SelectPicker;
