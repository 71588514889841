import React, { useEffect, useState } from 'react'
import './JobPostingSummaryAcrossCompanyReport.css'
import editIcon from '../../../assets/edit.png'
import deleteIcon from '../../../assets/delete.png'
import Modal from "react-modal";
import userProfile from '../../../assets/userProfile.svg'
import searchIcon from '../../../assets/searchLogo.svg'
import { ComponentAndScreenConstant } from '../../../constants/componentAndScreenConstant';
import { PortalUserRole, Url } from '../../../EnvoirnmentSetup/APIs';
import { emailValidationForJobPoster, field, onlyAlphabets, onlyNumber } from '../../../Validation/Validation';
import Lottie from "lottie-react";
import Oops from "../../../assets/Opps.json";
import completed from "../../../assets/Success.json";
import nodata from '../../../assets/nodata.png'
import loader from "../../../assets/PANLoader.json";
import { useSelectedCardContext } from '../../../Context/Context';
import deleteIcon2 from '../../../assets/deleteIcon.svg'
import closeIcon from '../../../assets/closeIcon.svg'
import moment from 'moment';
import nodatafound from '../../../assets/nodata.png'

const JobPostingSummaryAcrossCompanyReport = () => {
  const [editUMModal, setEditUMModal] = useState(false)
  const [editUser,setEditUser] = useState(false)
  const [countryCode, setCountryCode] = useState([])
  const [popupMsg, setPopupMsg] = useState("")
  const [errPopup1, setErrPopup1] = useState(false)
  const [successPopup,setSuccessPopup] = useState(false)
  const [newUserDetails, setNewUserDetails] = useState({fullName: field, cntryCode: "249", mno: field, email: field})
  const [teamList, setTeamList] = useState([])
  const [searchVal,setSearchVal] = useState("")
  const [fetching, setFetching] = useState(false);
  const { selectedCard, setSelectedCard } = useSelectedCardContext();
  const [deletemodalIsOpen,setDeletemodalIsOpen] = useState(false)
  const [userIdForDelete,setUserIdForDelete] = useState()
  const [fetchAgain,setFetchAgain] = useState(false)
  const [editUserId,setEditUserID] = useState("")
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "40%",
      borderRadius: "25px",
      padding: "10px",
    },
  };
  const customStyles1 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "35%",
      borderRadius: "25px",
      padding: "10px",
    },
  };
  const customStylesDeleteModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "25px",
      minWidth: "35%"
    },
  };
  const loaderstyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      // marginRight: '-50%',
      transform: "translate(-50%, -50%)",
      minWidth: "500px",
      borderRadius: "25px",
      border: "none",
      padding: "10px",
      backgroundColor: "transparent ",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
  };

  useEffect(() => {
    getDataByDates()
   
    setSelectedCard(7)
  }, [fetchAgain])
  
  const getDataByDates = () => {
      console.log("date",moment(fromDate).format("yyyy-MM-DD"))
    try {
      setFetching(true)
      let token = sessionStorage.getItem("token");

      fetch(
        Url?.getJobPostingSummaryAcrossCompanyReportdata, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
        .then((response) => response.json())
        .then((res) => {
          console.log("report data" ,res );
          if (res.SUCCESS) {
            setFetching(false)
            let newData = res.DATA?.map((i)=>{
              return {
                 ...i, combineMobilenumber: "+"+i?.user?.countryCode?.countryCode+" "+i?.user?.mobileNumber
               }
              })
   
            setTeamList(newData)
          }
          else{
            setFetching(false)
          }
        }).catch((e)=>{
          setFetching(false)
          console.log(e);
        })
    } catch (error) {
      setFetching(false)
      console.log("Experience", error);
    }
  };





const handleNavigateLinkedIn = (item) => {
  if(!!item?.user?.linkedinProfile){
    window.open(item?.user?.linkedinProfile);
  }
}

function closeDeleteModal() {
  setDeletemodalIsOpen(false);
}

const handleConfirmDeleteUser = () => {
  try{
    setFetching(true)
     fetch(Url.deletePoster.replace("{userId}",userIdForDelete),{
      method:"DELETE",
      headers:{
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Bearer " + sessionStorage.getItem("token")
      }
     })
     .then((resp)=>resp.json())
     .then((res)=>{
      console.log(res);
      if(res?.SUCCESS){
        setDeletemodalIsOpen(false)
        setPopupMsg("Recruiter deleted successfully.")
        setFetchAgain(!fetchAgain)
        setSuccessPopup(true)
      }
      else{
        setPopupMsg(res?.message)
        setErrPopup1(true)
        setDeletemodalIsOpen(false)
      }
      setFetching(false)
     })
     .catch((e)=>{
      console.log(e);
      setFetching(false)
     })
  }
  catch(e){
     console.log(e);
     setFetching(false)
  }
}


  return (
    <>{PortalUserRole == "ROLE_MANAGER"?
    <div>
       <div className="appcntRepoFixedContent">
        <div className="applcntRepoOuterDiv" style={{ marginTop: "5px" }}>
          <p style={{ color: '#007749', fontSize:window?.screen?.width>1023?'1.5rem':'1.1rem', marginTop: '15px',marginBottom:"5px" }}>Overall Job Postings</p>
        </div> 
       </div>
       {/* <div style={{display:"flex", justifyContent:"center", marginBottom:"20px"}}>
      <div className='JPSARCRteamReportMiddleContainerWrapper'>
        <div className="JPSARCRreportFromDate">
          <p style={{padding:"0px", margin:"5px", flex:2}}>From Date:</p>
        <ComponentAndScreenConstant.DatePickerComponent
                    placeholderText="Enter here"
                    label={""}
                    // minDate={new Date()}
                    maxDate={new Date()}
                    isRequired={true}
                    InputSty={{
                      backgroundColor: "#FFF",
                      textAlignVertical: "top",
                      // height: 100,
                      color: "#222B45",
                      fontSize: 14,
                    }}
                    selectedDate={new Date(fromDate)}
                    onChange={(e) => {
                      console.log(e);
                      setFromDate(e);
                      setToDate(new Date())
                    }}
                  />
        </div>
        <div className="JPSARCRreportFromDate">
          <p style={{padding:"0px", margin:"5px", flex:2}}>To Date:</p>
        <ComponentAndScreenConstant.DatePickerComponent
                    placeholderText="Enter here"
                    label={""}
                    minDate={new Date(fromDate)}
                    maxDate={new Date()}
                    isRequired={true}
                    InputSty={{
                      backgroundColor: "#FFF",
                      textAlignVertical: "top",
                      // height: 100,
                      color: "#222B45",
                      fontSize: 14,
                    }}
                    selectedDate={new Date(toDate)}
                    onChange={(e) => {
                      console.log(e);
                      setToDate(e);
                    }}
                  />
        </div>
        <button className="JPSARCRSubmitReportDates" onClick={()=>{getDataByDates()
        }}>Submit</button>
        </div>
        </div> */}
      <div className="EditJobOuterDiv" style={{width:"97%",margin:"0 auto"}}>
          <div className="EditJobTableSubDiv" style={{height:teamList?.length>7?"70vh":"40vh"}}>
          {teamList.length!=0?<table className="tableStyleMyJobs">
            <thead style={{position: "sticky", top:"0",height:"30px"}}>
                <tr
                  style={{backgroundColor: '#007749', color: '#fff' }}
                >
                  <th
                    colspan="1"
                    role="columnheader"                   
                    style={{borderTopLeftRadius:"10px",width:"5%",borderRight:"0.5px solid gray",backgroundColor: '#007749'}}
                    
                  >
                    <span>#</span>
                  </th>
                  
                  <th
                    colspan="1"
                    role="columnheader"                    
                    style={{ borderRight:" 0.5px solid gray", width:"25%",backgroundColor: '#007749'}}
                  >
                    <span>Recruiter Email</span>
                  </th>
                  <th
                    colspan="1"
                    role="columnheader"                    
                    style={{borderRight:" 0.5px solid gray", width:"10%",backgroundColor: '#007749'}}
                  >
                    <span>{moment(teamList[0]?.dayReport[0]?.date).format("DD-MMM-YYYY")}</span>
                  </th>
                  <th
                    colspan="1"
                    role="columnheader"                    
                    style={{borderRight:" 0.5px solid gray", width:"10%",backgroundColor: '#007749'}}
                  >
                    <span>{moment(teamList[0]?.dayReport[1]?.date).format("DD-MMM-YYYY")}</span>
                  </th>
                  <th
                    colspan="1"
                    role="columnheader"                    
                    style={{borderRight:" 0.5px solid gray", width:"10%",backgroundColor: '#007749'}}
                  >
                    <span>{moment(teamList[0]?.dayReport[2]?.date).format("DD-MMM-YYYY")}</span>
                  </th>
                  <th
                    colspan="1"
                    role="columnheader"                    
                    style={{borderRight:" 0.5px solid gray", width:"10%",backgroundColor: '#007749'}}
                  >
                    <span>{moment(teamList[0]?.dayReport[3]?.date).format("DD-MMM-YYYY")}</span>
                  </th>
                  <th
                    colspan="1"
                    role="columnheader"                    
                    style={{borderRight:" 0.5px solid gray", width:"10%",backgroundColor: '#007749'}}
                  >
                    <span>{moment(teamList[0]?.dayReport[4]?.date).format("DD-MMM-YYYY")}</span>
                  </th>
                  <th
                    colspan="1"
                    role="columnheader"                    
                    style={{borderRight:" 0.5px solid gray", width:"10%",backgroundColor: '#007749'}}
                  >
                    <span>{moment(teamList[0]?.dayReport[5]?.date).format("DD-MMM-YYYY")}</span>
                  </th>
                  <th
                    colspan="1"
                    role="columnheader"                    
                    style={{width:"10%",backgroundColor: '#007749',borderTopRightRadius:"10px"}}
                  >
                    <span>{moment(teamList[0]?.dayReport[6]?.date).format("DD-MMM-YYYY")}</span>
                  </th>
                  
                </tr>
            </thead>
            <tbody style={{borderBottom:"0.5px solid gray"}}>
              {
                teamList?.map((item,ind)=>{
                    return(
                        <tr style={{backgroundColor:ind%2==0?"#C1E7D8":"#ffff"}}>
                            <td className='JPSARCRtbodyTd'><span style={{display:"flex",justifyContent:"center",alignItems:"center"}}>{ind + 1}</span></td>
                            <td className='JPSARCRtbodyTd' style={{display:"flex",justifyContent:"start"}}><span className='JPSARCRtbodySpan' onClick={()=>handleNavigateLinkedIn(item)} style={{color:"blue"}}>{item?.user?.userName}</span></td>
                            <td className='JPSARCRtbodyTd'><span className='JPSARCRtbodySpan' style={{justifyContent:"end"}}>{item?.dayReport[0]?.jobCount}</span></td>
                            <td className='JPSARCRtbodyTd'><span className='JPSARCRtbodySpan' style={{justifyContent:"end"}}>{item?.dayReport[1]?.jobCount}</span></td>
                            <td className='JPSARCRtbodyTd'><span className='JPSARCRtbodySpan' style={{justifyContent:"end"}}>{item?.dayReport[2]?.jobCount}</span></td>
                            <td className='JPSARCRtbodyTd'><span className='JPSARCRtbodySpan' style={{justifyContent:"end"}}>{item?.dayReport[3]?.jobCount}</span></td>
                            <td className='JPSARCRtbodyTd'><span className='JPSARCRtbodySpan' style={{justifyContent:"end"}}>{item?.dayReport[4]?.jobCount}</span></td>
                            <td className='JPSARCRtbodyTd'><span className='JPSARCRtbodySpan' style={{justifyContent:"end"}}>{item?.dayReport[5]?.jobCount}</span></td>
                            <td className='JPSARCRtbodyTd' style={{borderRight:"none"}}><span className='JPSARCRtbodySpan' style={{justifyContent:"end"}}>{item?.dayReport[6]?.jobCount}</span></td>


                        </tr>
                    )
                })
              }
              
            </tbody>
          </table>
          :
          <div className="noNotificationMsg" style={{height:"50vh"}}>
            <img
              src={nodata}
              className="nodataImage"
            />
            <p>No Data Available.</p>
          </div>
          }
          </div>
          </div>
       {/*    <div style={{ display: "flex", justifyContent: "center" }} className="editJobpagination">
                <ComponentAndScreenConstant.TablePagination
                //   setPageNumber={setSelectedPageNumber}
                //   pagenumber={selectedPageNumber}
                //   totalpages={totalpages}
                //   isSavedPageNumberNeeded={true}
                />
              </div> */}
     
 

      <Modal
        isOpen={errPopup1}
        onRequestClose={() => setErrPopup1(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={() => setErrPopup1(false)} className="delAccModalCrossPara">×</p>
          </div>
        </div>
        <div style={{marginTop:-20}}>
          <Lottie
            animationData={Oops}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg" style={{padding:0}}>
          {popupMsg}
        </div>
        <div className="okBtnModal">
          <button onClick={() => { setErrPopup1(false) }}>OK</button>
        </div>
      </Modal>
      <Modal
        isOpen={successPopup}
        onRequestClose={() => setSuccessPopup(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
        shouldCloseOnOverlayClick={false}
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={() => setSuccessPopup(false)} className="delAccModalCrossPara">×</p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={completed}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg">
          {popupMsg}
        </div>
        <div className="okBtnModal">
          <button onClick={()=>setSuccessPopup(false)}>OK</button>
        </div>
      </Modal>

      <Modal
        isOpen={fetching}
        ariaHideApp={false}
        style={loaderstyle}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <Lottie
          animationData={loader}
          loop={true}
          style={{ width: "100px", height: "100px", margin: "0px auto" }}
        />
      </Modal>

     
    </div> :
    <div style={{width:"100%",height:"85vh",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
    <img src={nodatafound} style={{width:"20%"}}/>
    <span>Oops! No Page Found</span>
   </div>
    }</>
  )
}

export default JobPostingSummaryAcrossCompanyReport