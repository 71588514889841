import React, { useEffect, useState } from "react";
import "../JobDetails/JobDetails.css";
import { useNavigate, useLocation } from "react-router-dom";
import bag from "../../assets/bag.svg";
import calendar from "../../assets/viewLocation.svg"
import location from "../../assets/locationIcon.svg"
import { Url } from "../../EnvoirnmentSetup/APIs";
import Lottie from "lottie-react";
import moment from "moment";
import Modal from "react-modal";
import loader from "../../assets/PANLoader.json";
import deleteIcon from "../../assets/deleteIcon.svg";
import editIcon from "../../assets/editIcon.svg";
import breadCrumbArrow from '../../assets/backArrowGray.svg'
import workMode from '../../assets/workMode.svg'
import jobType from '../../assets/jobType.svg'
import openings from '../../assets/noOfOpenings.svg'

const JobDetails = () => {
  const navigate = useNavigate();

  const [getAllJobDetails, setGetAllJobDetails] = useState({});
  const [getPostApplicationStatus, setGetPostApplicationStatus] = useState({});
  const [fetching, setFetching] = useState(false);
  const [countmodalIsOpen, setCountmodalIsOpen] = useState(false);
  const [modalMsg, setModalMsg] = useState("");

  let propData = useLocation();
  let JobId = propData.state.jobId;

  // console.log('JobId****************',JobId);
  console.log(
    "GetPostApplicationStatus>>>>>>",
    JSON.stringify(getPostApplicationStatus)
  );

  const jobDetailsloaderstyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      // marginRight: '-50%',
      transform: "translate(-50%, -50%)",
      minWidth: "500px",
      borderRadius: "25px",
      border: "none",
      padding: "10px",
      backgroundColor: "transparent ",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
  };

  const customStylesDeleteModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "25px",
      minWidth:"25%",
    },
  };

  useEffect(() => {
    getJobData(JobId);
    GetPostApplicationStatus(JobId);
  }, []);

  function openCountModal() {
    setCountmodalIsOpen(true);
  }

  function closeCountModal() {
    setCountmodalIsOpen(false);
  }


  const getJobData = async (JobId) => {
    setFetching(true);
    try {
      let token = sessionStorage.getItem("token");
      fetch(Url.getJobDetailsByJobId.replace("{id}", JobId), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          console.log("getJobDetails*****---->", res);

          setFetching(false);
          if (res.SUCCESS) {
            setGetAllJobDetails(res?.DATA);
          }
        });
    } catch (error) {
      setFetching(false);
      console.log("getJobDetails", error);
    }
  };

  const GetPostApplicationStatus = async (JobId) => {
    setFetching(true);
    try {
      let token = sessionStorage.getItem("token");
      fetch(Url.GetPostApplicationStatus.replace("{jobId}", JobId), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          console.log("GetPostApplicationStatus*****---->", res);
          setFetching(false);
          if (res.SUCCESS) {
            setGetPostApplicationStatus(res?.DATA);
          }
        });
    } catch (error) {
      setFetching(false);
      console.log("GetPostApplicationStatus", error);
    }
  };
  useEffect(() => {
    const textarea = document.getElementById('myTextarea');

    if (textarea) {
      textarea.style.height = 'auto'; // Reset to auto height before recalculating
      textarea.style.height = `${textarea.scrollHeight}px`; // Set the height based on content
    }
  }, [getAllJobDetails?.description]);

  return (
    <div className="mainConatinerWrapper">
      {/* GO Back functionality */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "2px 20px",
        }}
      >
        <p className="jobDeatilsHeader">Job Details</p>
        <ul className="breadCrumb">
          <li style={{color:"#007749"}}>Job Details</li> 
          <img src={breadCrumbArrow} alt="" />
          <li onClick={()=>navigate(-1)}>{propData?.state?.breadCrumbVisible == "Company Jobs"? "All Jobs":"My Postings"}</li>
        </ul>
      </div>

      <div className="subMainContainer">
        <div className="jobCardsalary">
          Salary: {getAllJobDetails?.salary?
             getAllJobDetails?.countryCode?.currency + getAllJobDetails?.salary
            : ` ${getAllJobDetails?.countryCode?.currency} Not Disclosed`} {getAllJobDetails?.salary?"LPA":""}
        </div>
        <div className="mainInfoContainerWarapper">
          <div className="jobPosition">
            {getAllJobDetails?.designation
              ?.map((v) => v?.designationName)
              .join(", ")}
          </div>
          <div className="jobDetailsCmpnyDiv" style={{color:'#007749',marginTop:"5px"}}>{getAllJobDetails?.company?.companyName}</div>

          <div className="detailsCards">
            <div className="yearsDetailsDiv">
              <img src={bag} className="yearsDetailsLogo" />{" "}
              {getAllJobDetails?.experience?.experience}
            </div>
            <div className="dateDetailsDiv">
              <img src={calendar} className="dateDetailsLogo" />
              Active Till:{" "}
              {moment(getAllJobDetails?.tillDate).format("DD-MMM-YYYY")}
            </div>
            <div className="locationDetailsDiv" title={getAllJobDetails?.state?.map((v) => v?.stateName).join(", ")}>
              <img src={location} className="locationDetailsLogo" />
              <p className="jobDetailsLocation">
                {getAllJobDetails?.state?.map((v) => v?.stateName).join(", ")}
              </p>
            </div>
            <div className="yearsDetailsDiv">
              <img src={jobType} className="yearsDetailsLogo" />{" "}
              {getAllJobDetails?.jobType?.jobType}
            </div>
            <div className="yearsDetailsDiv">
              <img src={workMode} className="yearsDetailsLogo" />{" "}
              {getAllJobDetails?.workMode?.workMode}
            </div>
            <div className="yearsDetailsDiv">
              <img src={openings} className="yearsDetailsLogo" />{" "}
              Openings: {getAllJobDetails?.noOfOpenings}
            </div>
          </div>

          <div className="JobDescriptionTittle">Job Description</div>
          {/* <div className="DescriptionTittle"> */}
            {/* {getAllJobDetails?.description} */}
            <textarea className={window.screen.width>1020?"DescriptionTittle descriptionTxtarea":"DescriptionTittle descriptionTxtarea1"}  id='myTextarea' readOnly value={getAllJobDetails?.description}></textarea>
          {/* </div> */}

          <div className="KeywordsTittle">Keywords</div>
          <div className="displayKeywords">
            {getAllJobDetails?.skill?.map((v) => v?.name).join(", ")}
          </div>

          <div className="mainCounterWrapper">
            <div
              className="displayViewedDiv"
              onClick={() => {
                if (getPostApplicationStatus?.applicantCount?.intrested == 0) {
                  openCountModal();
                  setModalMsg( "No applicants so far.");
                } 
                else {
                  navigate("/dashboard/joblist/appliedby", {
                    state: {
                      flow: "Applied",
                      jobId: JobId,
                      jobDetails: getAllJobDetails,
                      showJobDetailBreadCrumb:true,
                      breadCrumbVisible:propData?.state?.breadCrumbVisible =="Company Jobs"? "Company Jobs" : "My Jobs"
                    },
                  });
                }
              }}
            >
              <div className="ViewedName">Applied</div>
              <div className="circleShow">
                {getPostApplicationStatus?.applicantCount?.intrested}
              </div>
            </div>

            <div
              className="displayViewedDiv"
              onClick={() => {
                if (getPostApplicationStatus?.applicantCount?.viewd == 0 && getPostApplicationStatus?.applicantCount?.unregisteredViews == 0) {
                  setModalMsg("No views yet.");
                  openCountModal();
                } 
                else if(getPostApplicationStatus?.applicantCount?.viewd == 0 && getPostApplicationStatus?.applicantCount?.unregisteredViews != 0){
                    if(getPostApplicationStatus?.applicantCount?.unregisteredViews==1){
                      setModalMsg("The only user is currently unregistered.")
                      openCountModal();
                    }else{
                       setModalMsg(`All the ${getPostApplicationStatus?.applicantCount?.unregisteredViews} users are unregistered visitors.`)
                       openCountModal();
                    }
                }
                else 
                navigate("/dashboard/joblist/viewdby", {
                  state: {
                    flow: "Viewed",
                    jobId: JobId,
                    jobDetails: getAllJobDetails,
                    showJobDetailBreadCrumb:true,
                    breadCrumbVisible:propData?.state?.breadCrumbVisible =="Company Jobs"? "Company Jobs" : "My Jobs"
                  },
                });
              }}
            >
              <div className="ViewedName">Viewed by</div>
              <div className="circleShow">
                {getPostApplicationStatus?.applicantCount?.viewd == 0 && getPostApplicationStatus?.applicantCount?.unregisteredViews==0?
                getPostApplicationStatus?.applicantCount?.viewd :
                getPostApplicationStatus?.applicantCount?.viewd == 0 && getPostApplicationStatus?.applicantCount?.unregisteredViews!=0?
                getPostApplicationStatus?.applicantCount?.unregisteredViews : getPostApplicationStatus?.applicantCount?.viewd
                }
              </div>
            </div>

            <div
              className="displayViewedDiv"
              onClick={() => {
                if (getPostApplicationStatus?.applicantCount?.sendto == 0) {
                  openCountModal();
                  setModalMsg("0 applicants notified.");
                } 
                else 
                navigate("/dashboard/joblist/sentto", {
                  state: {
                    flow: "Sent to",
                    jobId: JobId,
                    jobDetails: getAllJobDetails,
                    showJobDetailBreadCrumb:true,
                    breadCrumbVisible:propData?.state?.breadCrumbVisible =="Company Jobs"? "Company Jobs" : "My Jobs"
                  },  
                });
              }}
            >
              <div className="ViewedName">Sent To</div>
              <div className="circleShow">
                {getPostApplicationStatus?.applicantCount?.sendto}
              </div>
            </div>

            <div
              className="displayViewedDiv"
              onClick={() => {
                if (getPostApplicationStatus?.applicantCount?.interviewShortlisted == 0) {
                  openCountModal();
                  setModalMsg("No applicants shortlisted. ");
                } 
                else 
                navigate("/dashboard/job/shortlisted", {
                  state: {
                    flow: "Shortlisted",
                    jobId: JobId,
                    jobDetails: getAllJobDetails,
                    showJobDetailBreadCrumb:true,
                    breadCrumbVisible:propData?.state?.breadCrumbVisible =="Company Jobs"? "Company Jobs" : "My Jobs"
                  },  
                });
              }}
            >
              <div className="ViewedName">Shortlisted</div>
              <div className="circleShow">
                {getPostApplicationStatus?.applicantCount?.interviewShortlisted}
              </div>
            </div>

            <div
              className="displayViewedDiv"
              onClick={() => {
                if (getPostApplicationStatus?.applicantCount?.interviewScheduled == 0) {
                  openCountModal();
                  setModalMsg("No interviews are scheduled. ");
                } 
                else 
                navigate("/dashboard/job/interviewing", {
                  state: {
                    flow: "Interviewing",
                    jobId: JobId,
                    jobDetails: getAllJobDetails,
                    showJobDetailBreadCrumb:true,
                    breadCrumbVisible:propData?.state?.breadCrumbVisible =="Company Jobs"? "Company Jobs" : "My Jobs"
                  },  
                });
              }}
            >
              <div className="ViewedName">Interviewing</div>
              <div className="circleShow">
                {getPostApplicationStatus?.applicantCount?.interviewScheduled}
              </div>
            </div>

            <div
              className="displayViewedDiv"
              onClick={() => {
                if (getPostApplicationStatus?.applicantCount?.selected == 0) {
                  openCountModal();
                  setModalMsg("No applicants hired. ");
                } 
                else 
                navigate("/dashboard/job/hired", {
                  state: {
                    flow: "Hired",
                    jobId: JobId,
                    jobDetails: getAllJobDetails,
                    showJobDetailBreadCrumb:true,
                    breadCrumbVisible:propData?.state?.breadCrumbVisible =="Company Jobs"? "Company Jobs" : "My Jobs"
                  },  
                });
              }}
            >
              <div className="ViewedName">Hired</div>
              <div className="circleShow">
                {getPostApplicationStatus?.applicantCount?.selected}
              </div>
            </div>

            <div
              className="displayViewedDiv"
              onClick={() => {
                if (getPostApplicationStatus?.applicantCount?.closed == 0) {
                  openCountModal();
                  setModalMsg("No applicants are rejected. ");
                } 
                else 
                navigate("/dashboard/job/declined", {
                  state: {
                    flow: "Declined",
                    jobId: JobId,
                    jobDetails: getAllJobDetails,
                    showJobDetailBreadCrumb:true,
                    breadCrumbVisible:propData?.state?.breadCrumbVisible =="Company Jobs"? "Company Jobs" : "My Jobs"
                  },  
                });
              }}
            >
              <div className="ViewedName">Declined</div>
              <div className="circleShow">
                {getPostApplicationStatus?.applicantCount?.closed}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={fetching}
        ariaHideApp={false}
        style={jobDetailsloaderstyle}
        contentLabel="Example Modal"
      >
        <Lottie
          animationData={loader}
          loop={true}
          style={{ width: "100px", height: "100px", margin: "0px auto" }}
        />
      </Modal>
      <Modal
        isOpen={countmodalIsOpen}
        onRequestClose={closeCountModal}
        ariaHideApp={false}
        style={customStylesDeleteModal}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        {/* <div className="deleteModalWrapper"></div> */}
        <div className="delAccModalErrMsg" style={{margin:"auto"}}>
         <p>{modalMsg}</p>
          <div>
            <button
              style={{
                padding: "10px 20px",
                //margin: "10px",
                borderRadius: "5px",
                backgroundColor:"#007749",
                border:"none",
                color:"#ffff"
              }}
              onClick={closeCountModal}
            >OK
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default JobDetails;
