import React, { useEffect, useRef, useState } from "react";
import DatePicker  from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePickerComponent.css";
import calendarIcon from '../../assets/calendarIcon.svg'
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import moment from "moment";

const DatePickerComponent = ({
  label,
  isRequired,
  labelStyle = {},
  placeholderText = "",
  selectedDate,
  ParentFieldWrapper = {},
  onChange,
  minDate,
  maxDate,
  isReadOnly=false,
  setScrollBottom,
  paddingLeftRequired,
  customHeightRequired,
  disabled=false,
  minYearsRequired = 200,
  showMonthYearPicker=false,
  showFullMonthYearPicker=false,
  dateFormat="dd-MMM-yyyy"     
}) => {
  const datePickerRef = useRef(null);
  const handleOpenCalendar = () => {
    if (datePickerRef.current !== null) {
      datePickerRef.current.setOpen(true);
    }
    setScrollBottom && setScrollBottom(new Date())
  }
  const [years, setYears] = useState([])
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    useEffect(() => {
      let yearsArr = Array(minYearsRequired).fill(1).map((n, i) => ((new Date()).getFullYear() + i + 4) - minYearsRequired)
      if (minDate) {
          yearsArr = yearsArr.filter((i) => i >= moment(minDate).format('YYYY'))
      }
      if (maxDate) {
          yearsArr = yearsArr.filter((i) => i <= moment(maxDate).format('YYYY'))
      }
      setYears(yearsArr)
  }, [maxDate, minDate])
  return (
    <div style={{ width: "100%" }}>
      {label && (
        <label className="defaultLabelStyle" style={labelStyle}>
          {label}
          {isRequired && (
            <span style={{ color: "#BC0000", fontWeight: "bold" }}>{"*"}</span>
          )}
        </label>
      )}
      <div className="dateboxWrapper" style={ParentFieldWrapper}>
        <DatePicker
          selected={selectedDate}
          onChange={onChange}
          placeholderText={placeholderText}
          dateFormat={dateFormat}
          className={paddingLeftRequired? "dateBox paddingLeft" :customHeightRequired? "dateBox1 paddingLeft" :"dateBox"}
          minDate={minDate}
          maxDate={maxDate}
          readOnly={isReadOnly}
          ref={datePickerRef}
          onFocus={()=>setScrollBottom && setScrollBottom(new Date())}
          disabled={disabled}
          showFullMonthYearPicker={showFullMonthYearPicker}
          showMonthYearPicker={showMonthYearPicker}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
        }) => (
            <div
                style={{
                    margin: '0px 10px',
                    display: "flex",
                    justifyContent: 'space-between',
                }}
            >
                <button style={{ border: 'none', fontSize: '18px', fontWeight: '600',display:"flex",alignItems:"center"}} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                    <IoIosArrowBack />
                </button>
                <div style={{ padding: 0, margin: 0,display:"flex",gap:"5px"}}>
                    { dateFormat=="dd-MMM-yyyy" && <select
                        style={{ border: '1px solid #aeaeae',borderRadius:"3px",outline: 'none', backgroundColor: 'transparent', }}
                        value={months[moment(date).format('MM') - 1]}
                        onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                        }
                    >
                        {months.map((option) => (
                            <option key={option} value={option}>
                                &nbsp;{option}
                            </option>
                        ))}
                    </select>}
                    <select
                        style={{ border: '1px solid #aeaeae',borderRadius:"3px",outline: 'none', backgroundColor: 'transparent', width: '60px', }}
                        value={moment(date).format('YYYY')}
                        onChange={({ target: { value } }) => changeYear(value)}
                    >
                        {years.map((option) => (
                            <option key={option} value={option}>
                                &nbsp;{option}
                            </option>
                        ))}
                    </select>

                </div>
                <button style={{ border: 'none', fontSize: '18px', fontWeight: '600',display:"flex",alignItems:"center" }} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                    <IoIosArrowForward />
                </button>
            </div>
        )}
        />
        <div className="calendarIconDate"><img src={calendarIcon} style={{cursor:!disabled && "pointer"}} onClick={handleOpenCalendar}  alt="" /></div>
      </div>
    </div>
  );
};

export default DatePickerComponent;
