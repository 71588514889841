import React, { useEffect, useState } from 'react'
import Modal from "react-modal";
import '../../components/LicenseModal/LicenseModal.css'
import RazorpayCheckout from '../../screens/Payment/RazorpayCheckout';
import { Url } from '../../EnvoirnmentSetup/APIs';
import { useAuthContext, useSelectedCardContext } from '../../Context/Context';
import Lottie from "lottie-react";
import completed from "../../assets/Success.json";
import customerSupport from '../../assets/customerSupp.json'

const LicenseModal = ({ openModal, closeModal, readOnly = false, setLgOutPopup }) => {
  const { setWarning, setAlert, setIsLoading } = useSelectedCardContext()
  const [bundlePrice, setBundlePrice] = useState(0)
  const [userLicensePrice, setUserLicensePrice] = useState(0)
  const [companyLicensePrice, setCompanyLicensePrice] = useState(0)
  const [AmountEncriptionKey, setAmountEncriptionKey] = useState('')
  const [comboOffers, setComboOffers] = useState(false)
  const [packageDetail, setPackageDetail] = useState(true)
  const [packages, setPackages] = useState([])
  const [packageData, setPackageData] = useState({})
  const [autoRenew, setAutoRenew] = useState(true)
  const { userDetails, setUserDetails } = useAuthContext();
  const [promoPopup,setPromoPopup] = useState(false)
  const [promoMsg, setPromoMsg] = useState("")
  const [successPopup,setSuccessPopup] = useState(false)
  const [popupMsg, setPopupMsg] = useState("")
  const [gstObj,setGstObj] = useState({})

  const [purchaseItems, setPurchaseItems] = useState({
    fiveJobsBundle: userDetails?.isActive ? 0 : 1,
    userLicense: userDetails?.isActive ? 0 : 1,
    companyLincense: userDetails?.isActive ? 0 : 1
  })
  const [promoCodeData, setPromoCodeData] = React.useState()
  const [promoCode, setPromoCode] = React.useState('')

  let TotalJobBundleAmount = purchaseItems?.fiveJobsBundle * bundlePrice;
  let TotalUserLicenceAmount = purchaseItems?.userLicense * userLicensePrice;
  let TotalCompanyLicenceAmount = purchaseItems?.companyLincense * companyLicensePrice;
  let totalAmountBeforeGST = comboOffers ? packageData?.price :
    (TotalCompanyLicenceAmount + TotalJobBundleAmount + TotalUserLicenceAmount).toFixed(2)
  let totalAmountWithDiscount = totalAmountBeforeGST - (promoCodeData?.discountAmount ?? 0) > 0 ? totalAmountBeforeGST - (promoCodeData?.discountAmount ?? 0) : 0
  let totalAmountWithGST = (totalAmountWithDiscount + (totalAmountWithDiscount * (gstObj?.igst/100))).toFixed(2)

  const licenseModalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: window.screen.width > 1023 ? "40%" : "80%",
      borderRadius: "25px",
      padding: "10px",
      height: "auto",
      minHeight: "300px"
    },
  };
  
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: window.screen.width>1023?"450px":"30%",
      borderRadius: "25px",
      padding: "10px",
      width:"auto",
      maxWidth:window.screen.width>1023?'60%':'90%'
    },
  };

  useEffect(() => {
    GetPricingData()
    GetPricingPackageData()
    getGstDetails()
  }, [])

  const GetPricingData = async () => {
    let token = sessionStorage.getItem("token");

    fetch(Url?.getPricing, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: "Bearer " + token,
      },

    }).then((response) => response.json())
      .then((res) => {
        if (res.SUCCESS) {
          setAmountEncriptionKey(res.TOKEN)
          res.DATA.map((i) => {
            if (i?.licenseType == 'USER' && i?.status == true) {
              setUserLicensePrice(i?.price)
            } else if (i?.licenseType == 'COMPANY' && i?.status == true) {
              setCompanyLicensePrice(i?.price)
            } else if (i?.licenseType == 'JOB_BUNDLE' && i?.status == true) {
              setBundlePrice(i?.price)
            }
          })

        }
      })
      .catch((err) => {
        console.error("errors", err);

      })
  };

  const GetPricingPackageData = async () => {
    let token = sessionStorage.getItem("token");

    fetch(Url?.pricingPackages, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: "Bearer " + token,
      },

    }).then((response) => response.json())
      .then((res) => {
        if (res?.SUCCESS) {
          setAmountEncriptionKey(res.TOKEN)
          setPackages(res?.DATA)
          setPackageDetail(true)
          if (!userDetails?.isActive) {
            setComboOffers(true)
            setPackageData(res?.DATA[0])
            setPurchaseItems({
              fiveJobsBundle: res?.DATA[0]?.noOfJobs,
              userLicense: res?.DATA[0]?.noOfUser,
              companyLincense: res?.DATA[0]?.noOfCompany
            })
          }
        }
      })
      .catch((err) => {
        console.error("errors", err);

      })
  };

  const getGstDetails = async () => {
    let token = sessionStorage.getItem("token");
    fetch(Url?.gstDetails, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: "Bearer " + token,
      },
    }).then((response) => response.json())
      .then((res) => {
        if (res?.SUCCESS) {
          setGstObj(res?.DATA)
        }
      })
      .catch((err) => {
        console.error("errors", err);
      })
  };

  const handleDecreaseCount = (field, count) => {
    if (!userDetails?.isActive) {
      if (count > 1) {
        setPurchaseItems({ ...purchaseItems, [field]: count - 1 })
      }
    }
    else if (count > 0) {
      setPurchaseItems({ ...purchaseItems, [field]: count - 1 })
    }
  }
  const handleIncreaseCount = (field, count) => {
    if (count < 20) {
      setPurchaseItems({ ...purchaseItems, [field]: count + 1 })
    }
  }
  const handleCloseModal = (e) => {
    setPurchaseItems({ fiveJobsBundle: userDetails?.isActive ? 0 : 1, userLicense: userDetails?.isActive ? 0 : 1, companyLincense: userDetails?.isActive ? 0 : 1 })
    closeModal(false)
    setComboOffers(false)
    setPromoCodeData(null)
    setPromoCode('')
  }
  const handleInputChange = (e, field) => {
    let value = e?.target?.value
    if (value < 21 && value?.length < 4) {
      setPurchaseItems({ ...purchaseItems, [field]: value })
    }
  }

  const handleViewPackageDetail = (data) => {
    setPackageDetail(true)
    setPackageData(data)
  }

  const applyPromoCode = () => {
    if (!!!promoCode) {
      setWarning('Please enter promo code.')
      return
    } else if (promoCode.length < 3) {
      setWarning('Enter valid promo code.')
      return
    }
    else if (promoCode == promoCodeData?.couponCode) {
      setWarning('Promo code already applied.')
      return
    }

    let token = sessionStorage.getItem("token");

    fetch(Url.validatePromoCode.replace('{promocode}', promoCode), {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: "Bearer " + token,
      },
    }).then((response) => response.json())
      .then(res => {
        // console.log('promo code......', res);
        if (res.SUCCESS) {
          setPromoCodeData(res?.DATA)
          if(promoCode == "THREEMONTHSFREE" && !comboOffers){
            setPromoMsg(`Congrats! You've saved ₹${res?.DATA?.discountAmount * 3}.`)
            setPromoPopup(true)
          }
          else if (promoCode == "THREEMONTHSFREE" && comboOffers){
            setPromoMsg(`Congrats! You've saved ₹${res?.DATA?.discountAmount * 3}. Enjoy 3 months free.`)
            setPromoPopup(true)
          }
          else if (promoCode == "SIXMONTHSFREE" && !comboOffers) {
            setPromoMsg(`Congrats! You've saved ₹${res?.DATA?.discountAmount * 6}.`)
            setPromoPopup(true)
          }
          else if (promoCode == "SIXMONTHSFREE" && comboOffers) {
            setPromoMsg(`Congrats! You've saved ₹${res?.DATA?.discountAmount * 6}. \nEnjoy 6 months free.`)
            setPromoPopup(true)
          }
          else {
            // setAlert(`Promo code applied successfully \nyou saved ₹${res?.DATA?.discountAmount}.`)
            setPromoMsg(`Congrats! You've saved ₹${res?.DATA?.discountAmount}.`)
            setPromoPopup(true)
          }
        } else {
          setWarning(res?.message)
        }
      })
      .catch(err => {
        setWarning('Something went wrong please try again later.')
      })
  }

  const handleAutoRenewChkbox = (e) => {
    if (e?.target?.checked) {
      setAutoRenew(true)
    }
    else {
      setAutoRenew(false)
    }
  }

  return (
    <div>
      <Modal
        isOpen={openModal}
        onRequestClose={() => !readOnly && closeModal(false)}
        ariaHideApp={false}
        style={licenseModalStyle}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
        shouldCloseOnOverlayClick={!readOnly}
      >
        <div className="CmpnyDetailsCrossIcon" onClick={() => { readOnly ? setLgOutPopup(true) : handleCloseModal() }}>x</div>
        <div className="CmpnyDetailTitle" style={{ marginBottom: "5px" }}>{userDetails?.isActive ? 'License Upgrade' : 'Business Essentials'}</div>

        {/* {!userDetails?.isActive && */}
          <div className='comboDiv'>
            <span onClick={() => {
              if(userDetails?.isActive){
                // sessionStorage.getItem('validTill')
                setWarning('You have already purchased Base Plan, \nit is valid till: '+sessionStorage.getItem('validTill') )
              }
              else if(!userDetails?.isActive && comboOffers){
                setWarning('The Base Plan activation is mandatory \nto purchase Additional Licenses.')
              }
              else{
                setComboOffers(!comboOffers);
                setPackageDetail(false)
                setPromoCodeData(null)
                setPromoCode('')
                setPurchaseItems({
                  fiveJobsBundle: userDetails?.isActive ? 0 : 1,
                  userLicense: userDetails?.isActive ? 0 : 1,
                  companyLincense: userDetails?.isActive ? 0 : 1
                })
              }
            }}>Click here</span>
            &nbsp; for {!comboOffers ? "purchasing Base Plan" : "Additional Licenses"}
          </div>

        {!comboOffers ? <>
          <div className='licenseContentOuterDiv'>
            <div className='licenseContent'>
              <div><span>Company License <sub style={{ fontSize: "0.6rem", color: "gray" }}> ( per ₹{companyLicensePrice} )</sub> </span><span>:</span></div>
              <div>
                <span onClick={() => handleDecreaseCount("companyLincense", purchaseItems?.companyLincense)}>-</span>
                <input readOnly value={purchaseItems?.companyLincense} onChange={(e) => handleInputChange(e, "companyLincense")} />
                <span onClick={() => handleIncreaseCount("companyLincense", purchaseItems?.companyLincense)}>+</span>
              </div>
            </div>
            <div className='licenseContent'>
              <div><span>User License <sub style={{ fontSize: "0.6rem", color: "gray" }}> ( per ₹{userLicensePrice} )</sub> </span><span>:</span></div>
              <div>
                <span onClick={() => handleDecreaseCount("userLicense", purchaseItems?.userLicense)}>-</span>
                <input readOnly value={purchaseItems?.userLicense} onChange={(e) => handleInputChange(e, "userLicense")} />
                <span onClick={() => handleIncreaseCount("userLicense", purchaseItems?.userLicense)}>+</span>
              </div>
            </div>
            <div className='licenseContent'>
              <div><span>Jobs License<sub style={{ fontSize: "0.6rem", color: "gray" }}> ( per ₹{bundlePrice} )</sub> </span><span>:</span></div>
              <div>
                <span onClick={() => handleDecreaseCount("fiveJobsBundle", purchaseItems?.fiveJobsBundle)}>-</span>
                <input readOnly value={purchaseItems?.fiveJobsBundle} onChange={(e) => handleInputChange(e, "fiveJobsBundle")} />
                <span onClick={() => handleIncreaseCount("fiveJobsBundle", purchaseItems?.fiveJobsBundle)}>+</span>
              </div>
            </div>

          </div>
          <div className='totalOuterDiv'>
            <div className='totalContent'>
              <span><span>{purchaseItems?.companyLincense} X Company License</span><span>=</span></span>
              <span>₹{purchaseItems?.companyLincense * companyLicensePrice}</span>
            </div>

            <div className='totalContent'>
              <span><span>{purchaseItems?.userLicense} X User License</span><span>=</span></span>
              <span>₹{purchaseItems?.userLicense * userLicensePrice}</span>
            </div>

            <div className='totalContent'>
              <span><span>{purchaseItems?.fiveJobsBundle} X Job License</span><span>=</span></span>
              <span>₹{purchaseItems?.fiveJobsBundle * bundlePrice}</span>
            </div>

          </div>
          <div className='TotalPayableAmount gstDiv'>
            <span>Total Amount</span>
            <span>₹{totalAmountBeforeGST} </span>
          </div>
          {promoCodeData?.discountAmount &&
            <>
              <div className='totalContent'>
                <span><span>Discount</span></span>
                <span>{promoCodeData?.discountAmount != 0 && '-'} ₹{promoCodeData?.discountAmount}</span>
              </div>
              <div className='totalContent'>
                <span><span>Total Amount with Discount</span></span>
                <span>₹{totalAmountWithDiscount}</span>
              </div>
            </>}
          {/* <div className='gstDiv'>
          <span>₹{totalAmountWithDiscount} + 18% GST</span>
        </div> */}
          <div className='TotalPayableAmount gstDiv'>
            <span>Total Payable Amount (₹{totalAmountWithDiscount} + {Number(gstObj?.igst)}% GST)</span>
            <span>₹{totalAmountWithGST} </span>
          </div>
          <div className='promoWrapper' style={{ margin: '20px auto' }}>
          <small>Enter the promo code (If have any) to avail the discount.</small>
          <div className='promoCodeDiv'>
            <input
              name='promoCode'
              placeholder='Enter promo code here'
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value.toLocaleUpperCase())}
              onKeyDown={(e) => {
                if (e.key.toLocaleLowerCase() == 'enter') {
                  applyPromoCode()
                }
              }}
            />
            <button onClick={applyPromoCode}>Apply</button>
          </div>
          </div>

          <div style={{ display: "flex", justifyContent: "center", margin: "10px" }}>
            <RazorpayCheckout
              // ButtonName={"Proceed To Pay"}
              closeModal={handleCloseModal}
              setSuccessModal={setSuccessPopup}
              setSuccessMsg={setPopupMsg}
              setPromoPopup={setPromoPopup}
              totalAmount={totalAmountWithGST}
              amountEncryptionKey={AmountEncriptionKey}
              promoCodeAuditId={promoCodeData?.promocodeId}
              allPurchaseValues={{ ...purchaseItems, TotalJobBundleAmount, TotalUserLicenceAmount, TotalCompanyLicenceAmount }}
            />
          </div>
        </> :
          <>
            <div className='packagesWrapper'>
              {/* {!packageDetail ?
                packages?.map((item, ind) => {
                  return (
                    <div className='packageItem' onClick={() => handleViewPackageDetail(item)}>
                      <div className='packageName'>Package Name: <span title={item?.packageName}>{item?.packageName}</span></div>
                      <div className='packageName'>No. Of Companies: <span>{item?.noOfCompany}</span></div>
                      <div className='packageName'>No. Of Users: <span>{item?.noOfUser}</span></div>
                      <div className='packageName'>No. Of Jobs: <span>{item?.noOfJobs}</span></div>
                      <div className='packageName'>Duration (in days): <span>{item?.durationInDays}</span></div>
                      <div className='packageName'>Package Price (per month): <span>&#8377;{item?.price}</span></div>
                    </div>
                  )
                })
                : */}
                <>
                  <div className='packageDetailOuterDiv'>
                    <div className='packHeading'>{packageData?.packageName}</div>
                    <div className='packContent'>
                      <div className='packInnerContent'>
                        <div className='packNewDiv'><span>No. Of Companies</span><span>:</span></div>
                        <div>{packageData?.noOfCompany}</div>
                      </div>

                      <div className='packInnerContent'>
                        <div className='packNewDiv'><span>No. Of Users</span><span>:</span></div>
                        <div>{packageData?.noOfUser}</div>
                      </div>

                      <div className='packInnerContent'>
                        <div className='packNewDiv'><span>No. Of Jobs</span><span>:</span></div>
                        <div>{packageData?.noOfJobs}</div>
                      </div>

                      <div className='packInnerContent'>
                        <div className='packNewDiv'><span>Duration (in days)</span><span>:</span></div>
                        <div>{packageData?.durationInDays}</div>
                      </div>

                      <div className='packInnerContent'>
                        <div className='packNewDiv'><span>Package Price (per month)</span><span>:</span></div>
                        <div>&#8377;{packageData?.price}</div>
                      </div>

                      {promoCodeData?.discountAmount && <div className='packInnerContent'>
                        <div className='packNewDiv'><span>Discount</span><span>:</span></div>
                        <div>{promoCodeData?.discountAmount != 0 && '-'}&#8377;{promoCodeData?.discountAmount}</div>
                      </div>}

                      <div className='packInnerContent' style={{ borderTop: "1px solid #007749", paddingTop: 5 }}>
                        <div className='packNewDiv'><span>Total Payable Amount ( &#8377;{totalAmountWithDiscount} + {Number(gstObj?.igst)}% GST )</span><span>:</span></div>
                        <div style={{ color: "#007749", fontWeight: "bold" }}>&#8377;{
                          totalAmountWithGST
                          //  (packageData?.price + (packageData?.price* 0.18)).toFixed(2)
                        }</div>
                      </div>
                    </div>
                  </div>

                  <div className='promoWrapper'>
                    {/* <span>Use "<span>THREEMONTHSFREE</span>" promo code to avail 3 months free base plan</span> */}
                    <small>Enter the promo code (If have any) to avail the discount.</small>
                    <div className='promoCodeDiv'>
                      <input
                        name='promoCode'
                        placeholder='Enter promo code here'
                        value={promoCode}
                        onChange={(e) => setPromoCode(e.target.value.toLocaleUpperCase())}
                        onKeyDown={(e) => {
                          if (e.key.toLocaleLowerCase() == 'enter') {
                            applyPromoCode()
                          }
                        }}
                      />
                      <button onClick={applyPromoCode}>Apply</button>
                    </div>
                  </div>

                  {/* <div className='chckBxDiv'>
                    <input type='checkbox' checked={autoRenew} onChange={(e) => handleAutoRenewChkbox(e)} />
                    <p>Auto-renew. Stay uninterrupted!.</p>
                  </div> */}

                  <div style={{ display: "flex", justifyContent: "center", margin: "10px 30px 30px 30px" }}>
                    <RazorpayCheckout
                      // ButtonName={"Proceed To Pay"}
                      closeModal={handleCloseModal}
                      setPromoPopup={setPromoPopup}
                      setSuccessModal={setSuccessPopup}
                      setSuccessMsg={setPopupMsg}
                      totalAmount={totalAmountWithGST}
                      amountEncryptionKey={AmountEncriptionKey}
                      promoCodeAuditId={promoCodeData?.promocodeId}
                      packageData={packageData}
                      allPurchaseValues={{ ...purchaseItems, TotalJobBundleAmount, TotalUserLicenceAmount, TotalCompanyLicenceAmount }}
                    />
                  </div>
                </>
              {/* } */}
            </div>
          </>}
      </Modal>

      <Modal
        isOpen={promoPopup}
        onRequestClose={() => setPromoPopup(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
        shouldCloseOnOverlayClick={false}
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={() => setPromoPopup(false)} className="delAccModalCrossPara">×</p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={completed}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg" style={{margin:'10px',padding:0,fontWeight:"normal"}}>
          {promoMsg}
        </div>
        <div className="delAccModalErrMsg" style={{margin:'10px 10px 20px 10px',padding:0,fontWeight:"normal"}}>
          Do you want to proceed?
        </div>
        {/* <div className="okBtnModal">
          <button onClick={()=>setPromoPopup(false)}>OK</button>
        </div> */}
        <div className='promoBtnsWrapper'>
          {
            !comboOffers? 
            <RazorpayCheckout
              // ButtonName={"Proceed To Pay"}
              closeModal={handleCloseModal}
              setPromoPopup={setPromoPopup}
              setSuccessModal={setSuccessPopup}
              setSuccessMsg={setPopupMsg}
              totalAmount={totalAmountWithGST}
              amountEncryptionKey={AmountEncriptionKey}
              promoCodeAuditId={promoCodeData?.promocodeId}
              allPurchaseValues={{ ...purchaseItems, TotalJobBundleAmount, TotalUserLicenceAmount, TotalCompanyLicenceAmount }}
            />
            :
            <RazorpayCheckout
                      // ButtonName={"Proceed To Pay"}
                      closeModal={handleCloseModal}
                      setPromoPopup={setPromoPopup}
                      setSuccessModal={setSuccessPopup}
                      setSuccessMsg={setPopupMsg}
                      totalAmount={totalAmountWithGST}
                      amountEncryptionKey={AmountEncriptionKey}
                      promoCodeAuditId={promoCodeData?.promocodeId}
                      packageData={packageData}
                      allPurchaseValues={{ ...purchaseItems, TotalJobBundleAmount, TotalUserLicenceAmount, TotalCompanyLicenceAmount }}
            />
          }
          <button onClick={()=>setPromoPopup(false)}>Close</button>
        </div>
      </Modal>

      <Modal
        isOpen={successPopup}
        onRequestClose={() => {setSuccessPopup(false);handleCloseModal();setPromoPopup(false);window.location.reload(true)}}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
        shouldCloseOnOverlayClick={false}
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={() =>{setSuccessPopup(false);handleCloseModal();setPromoPopup(false);window.location.reload(true)}} className="delAccModalCrossPara">×</p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={customerSupport}
            loop={true}
            className="delAccModalErrLottie"
            style={{width:"150px",height:"auto",margin:"0 auto"}}
          />
        </div>
        <div className="delAccModalErrMsg" >
          {popupMsg}
        </div>
        <div className="okBtnModal">
          <button onClick={()=>{setSuccessPopup(false);handleCloseModal('paymentSuccess');setPromoPopup(false);window.location.reload(true)}}>OK</button>
        </div>
      </Modal>
    </div>
  )
}

export default LicenseModal