import React from "react";
import "./tablePagination.css";
import pag_arrow from '../../assets/pagination_arrow.svg'
import pag_arrowright from '../../assets/pag_arrowright.svg'
const TablePagination = ({
  pagenumber = {
    actualNumber: 0,
    gotoNumber: 0,
  },
  totalpages = 0,
  setPageNumber,
  isSavedPageNumberNeeded=false
}) => {
  console.log({ totalpages });
  const gotoPage = (type, value) => {
    switch (type) {
      case "First":
        setPageNumber({
          actualNumber: 1,
          gotoNumber: 1,
        });
        if(isSavedPageNumberNeeded){
          sessionStorage.setItem("storedPageNumber", 1);
        }
        break;
      case "Last":
        setPageNumber({
          ...pagenumber,
          actualNumber: totalpages,
          gotoNumber: totalpages,
        });
        if(isSavedPageNumberNeeded){
          sessionStorage.setItem("storedPageNumber", totalpages);
        }
        break;
      case "Previous":
        setPageNumber({
          actualNumber: pagenumber?.actualNumber - 1,
          gotoNumber: pagenumber?.actualNumber - 1,
        });
        if(isSavedPageNumberNeeded){
          sessionStorage.setItem(
            "storedPageNumber",
            pagenumber?.actualNumber - 1
          );
        }
       
        break;
      case "Next":
        setPageNumber({
          actualNumber: pagenumber?.actualNumber + 1,
          gotoNumber: pagenumber?.actualNumber + 1,
        });
        if(isSavedPageNumberNeeded){
          sessionStorage.setItem(
            "storedPageNumber",
            pagenumber?.actualNumber + 1
          );
        }
       
        break;
      case "Direct":
        if (!!value && value<=totalpages ) {
          setPageNumber({
            actualNumber: value,
            gotoNumber: value,
          });
          if(isSavedPageNumberNeeded){
            sessionStorage.setItem("storedPageNumber", value);
          }
       
        } else {
          setPageNumber({
            ...pagenumber,
            gotoNumber: "",
          });
        }
        break;

      default:
        break;
    }
  };

  return (
    <div className="paginationWrapper">
      <div className="paginationWrapperDiv1">
        {pagenumber?.actualNumber==1 ? <>
        <button className="FirstButton">
          First Page
        </button>
        <button className="PrevButton">
          {/* Previous Page */}
          <img className="arrowSize" src={pag_arrow} alt="" />
        </button>
        </>  :
        <>
        <button className="FirstButton" onClick={() => gotoPage("First")}>
          First Page
        </button>
        <button className="PrevButton" onClick={() => gotoPage("Previous")}>
          {/* Previous Page */}
          <img className="arrowSize" src={pag_arrow} alt="" />
        </button>
        </>
        
      }
        <p className="tablePaginationTotalPages">
          {pagenumber?.actualNumber}-{totalpages}
        </p>
        {pagenumber?.actualNumber==totalpages ? <>
        <button className="NextButton">
          {/* Next Page */}
          <img className="arrowSize" src={pag_arrowright} alt="" />
        </button>
        <button className="LastButton">
          Last Page
        </button>
        </> : 
        <>
        <button className="NextButton" onClick={() => gotoPage("Next")}>
          {/* Next Page */}
          <img className="arrowSize" src={pag_arrowright} alt="" />
        </button>
        <button className="LastButton" onClick={() => gotoPage("Last")}>
          Last Page
        </button>
        </>}
      </div>
      <div className="gotoPageDiv">
        <p className="gotoPageDivPara">Go to Page</p>
        <input
            type="number"
            value={pagenumber?.gotoNumber}
            onChange={(e) => {
              gotoPage("Direct", parseInt(e.target.value));
            }}
            className="gotoPageInput"/>
      </div>
    </div>
  );
};

export default TablePagination;
