import React, { useEffect, useState } from 'react';
import { Url } from '../../EnvoirnmentSetup/APIs';
import CryptoJS from 'crypto-js';
import Modal from "react-modal";
import Lottie from "lottie-react";
import completed from "../../assets/Success.json";
import { useAuthContext } from '../../Context/Context';
import loader from "../../../src/assets/PANLoader.json";
import Oops from "../../assets/Opps.json";
import { getAllLicensesInfo, getUserData } from '../../constants/constantFunctions';
import customerSupport from '../../assets/customerSupp.json'

const RazorpayCheckout = ({
  ButtonName="Proceed",
  totalAmount=0,
  amountEncryptionKey='',
  allPurchaseValues={},
  closeModal,
  setPromoPopup,
  promoCodeAuditId = null,
  packageData = null,
  setSuccessMsg,
  setSuccessModal,
}) => {
  const [successPopup,setSuccessPopup] = useState(false)
  const [popupMsg, setPopupMsg] = useState("")
  const { userDetails, setUserDetails } = useAuthContext();
  const [fetching, setFetching] = useState(false);
  const [FailmodalIsOpen, setFailmodalIsOpen] = useState(false);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      // minWidth: "40%",
      borderRadius: "25px",
      padding: "10px",
      width:window.screen.width>1023?'40%':'auto',
      maxWidth:window.screen.width<1023 && "90%"
    },
  };
  
  const loaderstyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      // marginRight: '-50%',
      transform: "translate(-50%, -50%)",
      minWidth: "500px",
      borderRadius: "25px",
      border: "none",
      padding: "10px",
      backgroundColor: "transparent ",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
  };

  let key =  CryptoJS.enc.Utf8.parse(amountEncryptionKey)

  const handleEncrypt = (inputText) => {
      const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(inputText?.toString()), key,
      {
        keySize: 128 / 8,
        iv: key,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
      return encrypted.toString()
    };
 

   const handleDecrypt = (encryptedText) => {
      const decrypted = CryptoJS.AES.decrypt(encryptedText, key, {
        keySize: 128 / 8,
        iv: key,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      let decrypteddata = decrypted.toString(CryptoJS.enc.Utf8);
      return decrypteddata;
    };


  const getPurchasingUserInfo = () => {
    if(totalAmount==0){
      setPopupMsg("Please add atleast one license.")
      setFailmodalIsOpen(true)
    }
    else{
    let token = sessionStorage.getItem("token");
    try {
      setFetching(true)
      fetch(Url.getuser, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((resp) => resp.json())
        .then((res) => {
          setFetching(false)
          const {fullName, userName, mobileNumber} = res.DATA
          createOrderId(fullName, userName, mobileNumber)
          
        })
        .catch((e) => {
          setFetching(false)
          console.log(e);
        });
    } catch (e) {
      setFetching(false)
      console.log(e);
    }
   }
  };


  const createOrderId= (customerName, customerEmail, customerMobileNumber)=>{
    let token = sessionStorage.getItem("token");
    let encryptedAmount = handleEncrypt(totalAmount)
    try {
     setFetching(true)
     const OrderDetails =  {
        customerName: customerName,
        phoneNumber:customerMobileNumber,
        amount: encryptedAmount,
        email: customerEmail
    }

      fetch(Url?.getOrderId, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(OrderDetails),
      })
        .then((response) => response.json())
        .then((res) => {
          setFetching(false)

          if (res.SUCCESS) {
            const {secretKey, razorpayOrderId} = res.DATA
           let  newsecretKey =   handleDecrypt(secretKey)
            let newrazorpayOrderId = handleDecrypt(razorpayOrderId)
            handlePayment(newsecretKey, newrazorpayOrderId, customerName, customerEmail, customerMobileNumber)
          }
        });
    } catch (error) {
      setFetching(false)
      console.log("called applicant err", error);
    }
  }


  const handlePayment = (RazorpayKeyID, orderId, customerName, customerEmail, customerMobileNumber ) => {
    const options = {
      key: RazorpayKeyID, // Replace with your Razorpay key ID
      name: customerName,
      image: 'https://www.shareresume.co/static/media/QuickHireLogo.b384512b4e42b7e21cba42c44f918001.svg',
      order_id: orderId,
      handler: function (response) {
        if(!!response.razorpay_payment_id){
          const { razorpay_order_id, razorpay_payment_id, razorpay_signature } = response
          confirmPayment(razorpay_order_id, razorpay_payment_id, razorpay_signature)
        }
      
      },
      prefill: {
        name: customerName,
        email: customerEmail,
        contact: customerMobileNumber
      },
      theme: {
        color: '#007749'
      }
    };
    
    const rzp = new window.Razorpay(options);

    rzp.on('payment.failed', function (response) {
      const {code,description, source, step, reason, metadata } =response.error
      failedPayment(metadata.order_id, metadata.payment_id,code,description, source, step, reason )
  });

    rzp.open();
  };

  const confirmPayment=  (razorpayOrderId, razorpayPaymentId, razorpaySignature)=>{
    let token = sessionStorage.getItem("token");
    let encryptedrazorpayOrderId = handleEncrypt(razorpayOrderId)
    let encryptedrazorpayPaymentId = handleEncrypt(razorpayPaymentId)
    let encryptedrazorpaySignature = handleEncrypt(razorpaySignature)
    try {
      setFetching(true)
     const PurchaseDetails =  {
      razorpayOrderId: encryptedrazorpayOrderId,
      razorpayPaymentId:encryptedrazorpayPaymentId,
      razorpaySignature: encryptedrazorpaySignature,
    }

      fetch(Url?.getOrderId, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(PurchaseDetails),
      })
        .then((response) => response.json())
        .then((res) => {
          setFetching(false)

          if (res.SUCCESS) {
            updateLicenceRecord(encryptedrazorpayOrderId, encryptedrazorpayPaymentId)
          }
        });
    } catch (error) {
      setFetching(false)
      console.log("called applicant err", error);
    }
  }

  const failedPayment=  (order_id, payment_id,code,description, source, step, reason)=>{

    let token = sessionStorage.getItem("token");
    let encryptedrazorpayOrderId = handleEncrypt(order_id)
    let encryptedrazorpayPaymentId = handleEncrypt(payment_id)
    try {
      setFetching(true)
     const PurchaseDetails =  {
      razorpayOrderId: encryptedrazorpayOrderId,
      razorpayPaymentId: encryptedrazorpayPaymentId,
      code: code,
      description:description,
      reason:reason,
      source:source,
      step:step
    }

      fetch(Url?.getOrderId, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(PurchaseDetails),
      })
        .then((response) => response.json())
        .then((res) => {
          setFetching(false)
          console.log("payment failed status response", res);

          if (res.SUCCESS) {
          }
        });
    } catch (error) {
      setFetching(false)
      console.log("called applicant err", error);
    }
  }



  const updateLicenceRecord = (encryptedrazorpayOrderId, encryptedrazorpayPaymentId) => {
    let token = sessionStorage.getItem("token");
    let encryptedAmountmanagerLicensePayment = handleEncrypt(allPurchaseValues?.TotalUserLicenceAmount)
    let encryptedAmountcompanyLicensePayment = handleEncrypt(allPurchaseValues?.TotalCompanyLicenceAmount)
    let encryptedAmountjobLicensePayment = handleEncrypt(allPurchaseValues?.TotalJobBundleAmount)

    try {
      setFetching(true)
     const licenceDetails =  {
      managerLicenseAuditDto: allPurchaseValues?.userLicense==0 ? null :  [
          {
              noOfLicense: allPurchaseValues?.userLicense,
              paymentType: "PAID",
              managerLicensePaymentDto:{
                  paymentAmount: encryptedAmountmanagerLicensePayment,
                  razorpayPaymentId: encryptedrazorpayPaymentId,
                  razorpayOrderId: encryptedrazorpayOrderId,
                  paymentStatus: "PAID"
              }
          }
      ],
      companyLicenseAuditDto: allPurchaseValues?.companyLincense==0 ? null : [
          {
              noOfLicense: allPurchaseValues?.companyLincense,
              paymentType: "PAID",
              companyLicensePaymentDto: {
                  paymentAmount: encryptedAmountcompanyLicensePayment,
                  razorpayPaymentId: encryptedrazorpayPaymentId,
                  razorpayOrderId: encryptedrazorpayOrderId,
                  paymentStatus: "PAID"
              }
          }
      ],
      jobLicenseAuditDto:allPurchaseValues?.fiveJobsBundle==0 ? null : [
          {
              noOfLicense: allPurchaseValues?.fiveJobsBundle,
              paymentType: "PAID",
              jobLicensePaymentDto: {
                  paymentAmount: encryptedAmountjobLicensePayment,
                  razorpayPaymentId: encryptedrazorpayPaymentId,
                  razorpayOrderId: encryptedrazorpayOrderId,
                  paymentStatus: "PAID"
              }
          }
      ],
      promoCodeAuditId: promoCodeAuditId ?? null,
      packageId: packageData?.packageId ?? null
  }

      fetch(Url?.updateLicencePaymentInfo, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(licenceDetails),
      })
        .then((response) => response.json())
        .then((res) => {
          setFetching(false)
          if (res.SUCCESS) {
            // setUserDetails((prevState) => ({
            //   ...prevState,
            //   userLicense:{...prevState?.userLicense, remainingUser: res?.DATA?.noOfLicense}, 
            //   jobLicense:{...prevState?.jobLicense, remainingJobPost: res?.DATA?.noOfJobs},
            //   companyLicense:{...prevState?.companyLicense, remainingCompany:res?.DATA?.noOfCompanies}
            // }))
            getAllLicensesInfo(userDetails,setUserDetails)
            getUserData(userDetails,setUserDetails)
            // setPopupMsg("Transaction Successful.")
            // setSuccessPopup(true)
            setSuccessMsg("Transaction Successful.")
            setSuccessModal(true)
            closeModal()
          }
        });
    } catch (error) {
      setFetching(false)
      console.log("called applicant err", error);
    }
  }
  
  const updatefreeLicenceRecord = () => {
    let token = sessionStorage.getItem("token");
    if(totalAmount == 0 && (allPurchaseValues?.fiveJobsBundle == 0 && allPurchaseValues?.userLicense==0 && allPurchaseValues?.companyLincense==0)){
      setPopupMsg("Please add atleast one license.")
      setFailmodalIsOpen(true)
      return
    }
    
    try {
      setFetching(true)
     const licenceDetails =  {
      managerLicenseAuditDto: allPurchaseValues?.userLicense==0 ? null :  [
          {
              noOfLicense: allPurchaseValues?.userLicense,
              paymentType: "FREE",
          }
      ],
      companyLicenseAuditDto: allPurchaseValues?.companyLincense==0 ? null : [
          {
              noOfLicense: allPurchaseValues?.companyLincense,
              paymentType: "FREE",
          }
      ],
      jobLicenseAuditDto:allPurchaseValues?.fiveJobsBundle==0 ? null : [
          {
              noOfLicense: allPurchaseValues?.fiveJobsBundle,
              paymentType: "FREE",
          }
      ],
      promoCodeAuditId: promoCodeAuditId ?? null
  }

      fetch(Url?.updateLicencePaymentInfo, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(licenceDetails),
      })
        .then((response) => response.json())
        .then((res) => {
          setFetching(false)
          if (res.SUCCESS) {
            // setUserDetails((prevState) => ({
            //   ...prevState,
            //   userLicense:{...prevState?.userLicense, remainingUser: res?.DATA?.noOfLicense}, 
            //   jobLicense:{...prevState?.jobLicense, remainingJobPost: res?.DATA?.noOfJobs},
            //   companyLicense:{...prevState?.companyLicense, remainingCompany:res?.DATA?.noOfCompanies}
            // }))
            getAllLicensesInfo(userDetails,setUserDetails)
            getUserData(userDetails,setUserDetails)
            // setPopupMsg("Licenses are successfully added to your account. Our support team will contact you for payment assistance.")
            // setPopupMsg("Licenses are successfully added to your account.")
            // setSuccessPopup(true)
            setSuccessMsg("Licenses are successfully added to your account.")
            setSuccessModal(true)
            closeModal()
          }
        });
    } catch (error) {
      setFetching(false)
      console.log("called applicant err", error);
    }
  }


  return (
    <>
    <button onClick={ totalAmount == 0 ? updatefreeLicenceRecord : getPurchasingUserInfo} className="addMore" style={{width:"200px", padding:"10px", fontSize:"14px",border:"none",outline:"none"}}>
      {ButtonName}
    </button>

    {/* <Modal
        isOpen={successPopup}
        onRequestClose={() => {setSuccessPopup(false);closeModal();setPromoPopup(false);window.location.reload(true)}}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
        shouldCloseOnOverlayClick={false}
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={() =>{setSuccessPopup(false);closeModal();setPromoPopup(false);window.location.reload(true)}} className="delAccModalCrossPara">×</p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={customerSupport}
            loop={true}
            className="delAccModalErrLottie"
            style={{width:"150px",height:"auto",margin:"0 auto"}}
          />
        </div>
        <div className="delAccModalErrMsg" >
          {popupMsg}
        </div>
        <div className="okBtnModal">
          <button onClick={()=>{setSuccessPopup(false);closeModal('paymentSuccess');setPromoPopup(false);window.location.reload(true)}}>OK</button>
        </div>
      </Modal> */}

      <Modal
        isOpen={FailmodalIsOpen}
        onRequestClose={()=>setFailmodalIsOpen(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p className="delAccModalCrossPara" onClick={()=>setFailmodalIsOpen(false)}>
              ×
            </p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={Oops}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg">{popupMsg}</div>
        <div className="modalOkBtnDiv">
        <button className="modalOkBtn" onClick={()=>setFailmodalIsOpen(false)}>OK</button>
        </div>
      </Modal>

      <Modal
        isOpen={fetching}
        ariaHideApp={false}
        style={loaderstyle}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <Lottie
          animationData={loader}
          loop={true}
          style={{ width: "100px", height: "100px", margin: "0px auto" }}
        />
      </Modal>
    </>
  );
};

export default RazorpayCheckout;
