import React, { useEffect, useState } from 'react'
import '../Auth/Register.css'
import HireMeLogo from '../../assets/quikHireLogo.png'
import OtpInput from 'react-otp-input';
import { PortalUserRole, Url } from '../../EnvoirnmentSetup/APIs';
import { emailValidationForJobPoster, field, onlyAlphabets, onlyLinkedInProfile, onlyNumber } from '../../Validation/Validation';
import { useNavigate, useParams } from 'react-router-dom';
import Lottie from "lottie-react";
import Oops from "../../assets/Opps.json";
import completed from "../../assets/Success.json";
import Modal from "react-modal";
import eyeIcon from '../../assets/eyeIcon.svg'
import hiddenEyeIcon from '../../assets/hiddenEyeIcon.svg'
import { SHA256 } from 'crypto-js';
import quik from '../../assets/Quik.svg'
import Qregister from '../../assets/Qregister.svg'
import { useAuthContext } from '../../Context/Context';

const Register = () => {
  const [otp, setOtp] = useState('');
  const [otp2, setOtp2] = useState('');
  const [pwdScreen, setPwdScreen] = useState(false)
  const [resendOtp, setResendOtp] = useState({ mobile: false, email: false })
  const [auditId, setAuditId] = useState({ messageAuditId: "", emailAuditId: "" })
  const [errPopup1, setErrPopup1] = useState(false)
  const [successPopup, setSuccessPopup] = useState(false)
  const [successPopup1, setSuccessPopup1] = useState(false)
  const [hidePwd, setHidePwd] = useState({ npwd: false, cnpwd: false })
  const [isOtpSent, setIsOtpSent] = useState({ mno: false, email: false })
  const [countryCodes, setCountryCodes] = useState([])
  const [popupMsg, setPopupMsg] = useState("")
  const [userIDEditUser, setUserIDEditUser] = useState("")
  const [errMsg, setErrMsg] = useState({ fullName: "", mno: "", email: "", linkedInProfile: "", pwd: "", cpwd: "" })
  const [newUserDetails, setNewUserDetails] = useState({
    fullName: field, cntryCode: "249", mno: field, email: field,
    mnoChkBx: true, emailChkBx: true, linkedInProfile: { ...field, fieldValue: "https://www.linkedin.com/in/" }, pwd: "", cpwd: ""
  })
  const [invalidOTP, setInvalidOTP] = useState({ mno: false, email: false })
  const [hideEmailField,setHideEmailField] = useState(false)
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "40%",
      borderRadius: "25px",
      padding: "10px",
    },
  };
  const navigate = useNavigate()
  const { userDetails, setUserDetails } = useAuthContext();

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.async = true;
  //   script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-10814484075';
  //   document.head.appendChild(script);

  //   const gtagScript = document.createElement('script');
  //   gtagScript.innerHTML = `
  //     window.dataLayer = window.dataLayer || [];
  //     function gtag(){dataLayer.push(arguments);}
  //     gtag('js', new Date());

  //     gtag('config', 'AW-10814484075');
  //   `;
  //   document.head.appendChild(gtagScript);
  // }, []);

  useEffect(() => {
    if (PortalUserRole == "ROLE_RECRUTER") {
      handleSendClick()
    }
    let fullURL = window.location.href;
    console.log({ fullURL });
    fullURL = fullURL.replace("#", "")
    const url = new URL(fullURL);
    console.log({ url });

    let userParamsId = !!url.searchParams.get("userid") ? atob(url.searchParams.get("userid")) : "";
    let userParamsFullName = !!url.searchParams.get("username") ? atob(url.searchParams.get("username")) : "";
    let userParamsCCId = !!url.searchParams.get("CCId") ? atob(url.searchParams.get("CCId")) : "";
    let userParamsmobNumber = !!url.searchParams.get("mobilenumber") ? atob(url.searchParams.get("mobilenumber")) : "";
    let userParamsEmail = !!url.searchParams.get("email") ? atob(url.searchParams.get("email")) : "";

    console.log({ userParamsId })

    setUserIDEditUser(userParamsId)
    if (!!userParamsId) {
      setNewUserDetails({ ...newUserDetails, mno: onlyNumber(userParamsmobNumber, 'Mobile Number', 10), cntryCode: userParamsCCId, fullName: onlyAlphabets('Full Name', userParamsFullName), email: emailValidationForJobPoster('Email Address', userParamsEmail) })
      setHideEmailField(true)
    }
    else{
      setHideEmailField(false)
    }
      fetch(Url.CountryCodes, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      })
        .then((resp) => resp.json())
        .then((res) => {
          console.log("country code api response", res);
          setCountryCodes(res?.DATA)
        })
        .catch((e) => {
          console.log(e);
        })
  }, [])

  useEffect(() => {
    handleOtpVerify()
  }, [otp])
  useEffect(() => {
    handleOtpVerifyForEmail()
  }, [otp2])

  console.log("userName", newUserDetails); // Output: 7756886834



  var bothUpperLowerCase = /^(?=.*[a-z])(?=.*[A-Z]).+$/
  var lowerCase = /.*[a-z].*/
  var upperCase = /.*[A-Z].*/
  var oneDigitRegex = /.*[0-9].*/;
  var oneSpclChar = /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?/~`]).+$/
  const handleRegInputChange = (e, field) => {
    console.log(e.target.value)
    if (field == "cntryCode") {
      setNewUserDetails({ ...newUserDetails, cntryCode: e?.target?.value })
    }
    else if (field == "mnoChkBx") {
      setNewUserDetails({ ...newUserDetails, mnoChkBx: !newUserDetails.mnoChkBx })
    }
    else if (field == "emailChkBx") {
      setNewUserDetails({ ...newUserDetails, emailChkBx: !newUserDetails.emailChkBx })
    }
    else if (field == "pwd") {
      if (e?.target?.value == "") {
        setErrMsg({ ...errMsg, pwd: "Password is required." })
      }
      else if (!upperCase.test(e?.target?.value)) {
        setErrMsg({ ...errMsg, pwd: "Should accept atleast one uppercase letter." })
      }
      else if (!lowerCase.test(e?.target?.value)) {
        setErrMsg({ ...errMsg, pwd: "Should accept atleast one lowercase letter." })
      }
      else if (!oneDigitRegex.test(e?.target?.value)) {
        setErrMsg({ ...errMsg, pwd: "Should accept atleast one digit." })
      }
      else if (!oneSpclChar.test(e?.target?.value)) {
        setErrMsg({ ...errMsg, pwd: "Should accept atleast one special character." })
      }
      else if (e?.target?.value.length < 8) {
        setErrMsg({ ...errMsg, pwd: "Minimum length should be 8." })
      }
      else if (e?.target?.value.length > 16) {
        setErrMsg({ ...errMsg, pwd: "Maximum length should be 16." })
      }
      else {
        setErrMsg({ ...errMsg, pwd: "" })
      }
      setNewUserDetails({ ...newUserDetails, pwd: e?.target?.value })
    }
    else if (field == "cpwd") {
      if (e?.target?.value == "") {
        setErrMsg({ ...errMsg, cpwd: "Confirm Password is required." })
      }
      else if (e?.target?.value !== newUserDetails?.pwd) {
        setErrMsg({ ...errMsg, cpwd: "Password doesn't match." })
      } else {
        setErrMsg({ ...errMsg, cpwd: "" })
      }
      setNewUserDetails({ ...newUserDetails, cpwd: e?.target?.value })
    }
  }


  const onlyCorporateMails = /^[a-zA-Z0-9._%+-]+@(?!yahoo\.|gmail\.|hotmail\.)(?!yahoo|gmail|hotmail)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

  const onTextChange = (fields, val) => {
    //console.log(fields)
    switch (fields) {
      case 'Full Name':
        setNewUserDetails({
          ...newUserDetails,
          fullName: onlyAlphabets(fields, val),
        });
        break;
      case 'Email Address':
        setAuditId({ ...auditId, emailAuditId: "" })
        setResendOtp({ ...resendOtp, email: false })
        setOtp2('')
        setNewUserDetails({
          ...newUserDetails,
          email: emailValidationForJobPoster(fields, val),
        });
        break;
      case 'Mobile Number':
        setAuditId({ ...auditId, messageAuditId: "" })
        setResendOtp({ ...resendOtp, mobile: false })
        setOtp('')
        if (val.length <= 10) {
          setNewUserDetails({
            ...newUserDetails,
            mno: onlyNumber(val, fields, 10),
          })
        }
        break;
      case 'LinkedIn Profile':
        setNewUserDetails({
          ...newUserDetails,
          linkedInProfile: onlyLinkedInProfile(fields, val),
        });
        break;
    }
  };

  const handleRegisterUser = () => {
    let updatedLinkedInUrl;
    let completeUrl = newUserDetails?.linkedInProfile?.fieldValue?.toLowerCase()
    if (completeUrl?.startsWith("http://www.") || completeUrl?.startsWith("https://www.") || completeUrl?.startsWith("http://in.") || completeUrl?.startsWith("https://in.")) {
      updatedLinkedInUrl = completeUrl
    }
    else if (completeUrl?.startsWith("www.") || completeUrl?.startsWith("in.")){
      updatedLinkedInUrl = `https://${completeUrl}`
    }
    else if (completeUrl?.startsWith("https://") || completeUrl?.startsWith("http://")) {
      let newLink = completeUrl
      let colIndex = newLink.indexOf(":") + 3
      let lastIndex = newLink.length
      let strAfterRemove = newLink.slice(colIndex, lastIndex)
      updatedLinkedInUrl = `https://www.${strAfterRemove}`
    }
    else {
      updatedLinkedInUrl = `https://www.${completeUrl}`
    }
    const obj = {
      userId: !!userIDEditUser ? userIDEditUser : null,
      messageAuditId: auditId?.messageAuditId,
      messageOTP: otp,
      emailAuditId: hideEmailField? 0 :auditId?.emailAuditId,
      emailOTP: hideEmailField? 1111:otp2,
      messageConsent: newUserDetails?.mnoChkBx,
      emailConsent: newUserDetails?.emailChkBx,
      web: true,
      fullName: newUserDetails?.fullName?.fieldValue,
      mobileNumber: newUserDetails?.mno?.fieldValue,
      userName: newUserDetails?.email?.fieldValue?.toLowerCase(),
      password: SHA256(newUserDetails?.pwd).toString(),
      linkedinProfile: updatedLinkedInUrl?.toLowerCase(),
      countryCode: {
        countryCodeId: newUserDetails?.cntryCode
      },
      roles: [
        { name: PortalUserRole == "ROLE_MANAGER" ? "ROLE_MANAGER" : "ROLE_RECRUTER" }
      ]
    }
    if (newUserDetails?.pwd == "") {
      setPopupMsg("Password field is required.")
      setErrPopup1(true)
    }
    else if (!upperCase.test(newUserDetails?.pwd)) {
      setPopupMsg("Password should contain atleast one uppercase letter.")
      setErrPopup1(true)
    }
    else if (!lowerCase.test(newUserDetails?.pwd)) {
      setPopupMsg("Password should contain atleast one lowercase letter.")
      setErrPopup1(true)
    }
    else if (!oneDigitRegex.test(newUserDetails?.pwd)) {
      setPopupMsg("Password should contain atleast one digit letter.")
      setErrPopup1(true)
    }
    else if (!oneSpclChar.test(newUserDetails?.pwd)) {
      setPopupMsg("Password should contain atleast one special character.")
      setErrPopup1(true)
    }
    else if (newUserDetails?.pwd.length < 8) {
      setPopupMsg("Password minimum length should be 8.")
      setErrPopup1(true)
    }
    else if (newUserDetails?.pwd.length > 16) {
      setPopupMsg("Password maximum length should be 16.")
      setErrPopup1(true)
    }
    else if (newUserDetails?.cpwd == "") {
      setPopupMsg("Confirm Password is required.")
      setErrPopup1(true)
    }
    else if (newUserDetails?.cpwd != newUserDetails?.pwd) {
      setPopupMsg("Password doesn't match.")
      setErrPopup1(true)
    }
    else {
      try {
        fetch(Url.registerUser, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          body: JSON.stringify(obj)
        }).then((resp) => resp.json())
          .then((res) => {
            console.log("register api response", res);
            if (res?.SUCCESS == true) {
              setPopupMsg("Thank you for signing up!\nPlease check your email for confirmation and further details.\n(Promo Codes, Latest Updates etc..)")
              setSuccessPopup(true)
            }
            else {
              setPopupMsg(res?.message)
              setErrPopup1(true)
            }
          })
          .catch((e) => {
            console.log(e);
          })
      }
      catch (e) {
        console.log(e);
      }
    }
  }
  const handleSendOtp = () => {
    let obj = {
      countryCodeId: newUserDetails?.cntryCode,
      toNumber: newUserDetails?.mno?.fieldValue,
      messageType: "OTP_SMS",
      registerd: false
    }

    if (!(!!newUserDetails?.mno?.fieldValue)) {
      setPopupMsg("Please enter the mobile number.")
      setErrPopup1(true)
    }
    else if (newUserDetails?.mnoChkBx == false) {
      setPopupMsg("Please select the checkbox to receive text messages.")
      setErrPopup1(true)
    }
    else if (!!newUserDetails?.mno?.errorField) {
      setPopupMsg(newUserDetails?.mno?.errorField)
      setErrPopup1(true)
    }
    else {
      try {
        fetch(Url.SendOtp, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify(obj)
        }).then((resp) => resp.json())
          .then((res) => {
            console.log("send OTP api response", res);
            if (res.SUCCESS == true) {
              setIsOtpSent({ ...isOtpSent, mno: true })
              setResendOtp({ ...resendOtp, mobile: true })
              setAuditId({ ...auditId, messageAuditId: res?.DATA })
              setPopupMsg("OTP sent successfully.")
              setSuccessPopup1(true)
            }
            else {
              setPopupMsg(res?.message)
              setErrPopup1(true)
            }
          })
          .catch((e) => {
            console.log(e);
          })
      }
      catch (e) {
        console.log(e);
      }
    }
  }

  const handleEmailOtpSend = () => {

    let obj = {
      toEmail: newUserDetails?.email?.fieldValue,
      messageType: "OTP_EMAIL",
      registerd: false
    }
    if (!(!!newUserDetails?.email?.fieldValue)) {
      setPopupMsg("Please enter the Email Id.")
      setErrPopup1(true)
    }
    else if (newUserDetails?.emailChkBx == false) {
      setPopupMsg("Please select the checkbox to receive an email.")
      setErrPopup1(true)
    } else if (!!newUserDetails?.email?.errorField) {
      setPopupMsg(newUserDetails?.email?.errorField)
      setErrPopup1(true)
    }
    else {
      try {
        fetch(Url.SendOtp, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify(obj)
        }).then((resp) => resp.json())
          .then((res) => {
            console.log("send OTP api response", res);
            if (res?.SUCCESS == true) {
              setIsOtpSent({ ...isOtpSent, email: true })
              setResendOtp({ ...resendOtp, email: true })
              setAuditId({ ...auditId, emailAuditId: res?.DATA })
              setPopupMsg("OTP sent successfully.")
              setSuccessPopup1(true)
            }
            else {
              setPopupMsg(res?.message)
              setErrPopup1(true)
            }
          })
          .catch((e) => {
            console.log(e);
            setPopupMsg("Something Went Wrong.")
            setErrPopup1(true)
          })
      }
      catch (e) {
        console.log(e);
      }
    }
  }
  const handleShowNpwd = () => {
    setHidePwd({ ...hidePwd, npwd: !hidePwd?.npwd })
  }
  const handleShowCnpwd = () => {
    setHidePwd({ ...hidePwd, cnpwd: !hidePwd?.cnpwd })
  }

  const handleNxtScreen = () => {
    if (!(!!newUserDetails?.fullName?.fieldValue)) {
      setPopupMsg("Full Name is required.")
      setErrPopup1(true)
    }
    else if (!newUserDetails?.fullName?.isValidField) {
      setPopupMsg(newUserDetails?.fullName?.errorField)
      setErrPopup1(true)
    }
    else if (!(!!newUserDetails?.mno?.fieldValue)) {
      setPopupMsg("Mobile No. is required.")
      setErrPopup1(true)
    }
    else if (!newUserDetails?.mno?.isValidField) {
      setPopupMsg(newUserDetails?.mno?.errorField)
      setErrPopup1(true)
    }
    // else if (newUserDetails?.mno.length < 4) {
    //   setPopupMsg("Mobile No. minimum length should be 4")
    //   setErrPopup1(true)
    // }
    // else if (newUserDetails?.mno.length > 10) {
    //   setPopupMsg("Mobile No. maximum length should be 10")
    //   setErrPopup1(true)
    // }
    else if (otp == "") {
      setPopupMsg("OTP is required to verify Mobile No.")
      setErrPopup1(true)
    }
    else if (otp.length < 4) {
      setPopupMsg("Invalid OTP provided to verify Mobile No.")
      setErrPopup1(true)
    }
    else if (invalidOTP?.mno) {
      setPopupMsg("The entered Mobile No. OTP is invalid.")
      setErrPopup1(true)
    }
    else if (!(!!newUserDetails?.email?.fieldValue) && !hideEmailField) {
      setPopupMsg("Email is required.")
      setErrPopup1(true)
    }
    else if (!newUserDetails?.email?.isValidField && !hideEmailField) {
      setPopupMsg(newUserDetails?.email?.errorField)
      setErrPopup1(true)
    }
    else if (otp2 == "" && !hideEmailField) {
      setPopupMsg("OTP is required to verify Email.")
      setErrPopup1(true)
    }
    else if (otp2.length < 4 && !hideEmailField) {
      setPopupMsg("Invalid OTP provided to verify Email.")
      setErrPopup1(true)
    }
    else if (invalidOTP?.email && !hideEmailField) {
      setPopupMsg("The entered Email OTP is invalid.")
      setErrPopup1(true)
    }
    else if (!(!!newUserDetails?.linkedInProfile?.fieldValue)) {
      setPopupMsg("LinkedIn Profile is required.")
      setErrPopup1(true)
    }
    else if (!newUserDetails?.linkedInProfile?.isValidField) {
      setPopupMsg(newUserDetails?.linkedInProfile?.errorField)
      setErrPopup1(true)
    }
    else if (isOtpSent.mno == false) {
      setPopupMsg("Please send the OTP to verify Mobile no.")
      setErrPopup1(true)
    }
    else if (isOtpSent.email == false && !hideEmailField) {
      setPopupMsg("Please send the OTP to verify Email id.")
      setErrPopup1(true)
    }
    else if (newUserDetails?.mnoChkBx == false) {
      setPopupMsg("Please select the checkbox to receive text messages.")
      setErrPopup1(true)
    }
    else if (newUserDetails?.emailChkBx == false && !hideEmailField) {
      setPopupMsg("Please select the checkbox to receive an email.")
      setErrPopup1(true)
    }
    else if (newUserDetails?.linkedInProfile?.fieldValue == "https://www.linkedin.com/in/") {
      setPopupMsg("Please provide valid LinkedIn URL.")
      setErrPopup1(true)
    }
    else {
      setPwdScreen(true)
    }
  }

  const handleSendClick = () => {
    // window.dataLayer = window.dataLayer || [];
    // function gtag() { window.dataLayer.push(arguments); }
    // gtag('event', 'send', {
    //   'event_category': 'Button',
    //   'event_label': 'Send Button',
    //   'value': 1
    // });


    // Your code to handle what happens when the OK button is clicked
    console.log("OK button clicked");

    // Send event to Google Analytics
    window.gtag('event', 'registration_completed', {
      'event_category': 'registration',
      'event_label': 'Registration Completed',
      'value': 1
    });
  };

  const handleAutoLogin = () => {

    if (PortalUserRole == "ROLE_RECRUTER") {
      handleSendClick()
    }

    setSuccessPopup(false)
    let obj = {
      countryCodeId: newUserDetails?.cntryCode,
      username: newUserDetails?.mno?.fieldValue,
      password: SHA256(newUserDetails?.pwd).toString(),
      role: PortalUserRole == "ROLE_MANAGER" ? "MANAGER" : "POSTER"
    }
    try {
      fetch(Url?.UserLogin, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(obj)
      }).then((response) => response.json())
        .then((ress) => {
          console.log("validate login api response", ress);
          if (ress?.SUCCESS == true) {
            setNewUserDetails({
              fullName: field, cntryCode: "249", mno: field, email: field, mnoChkBx: true, emailChkBx: true,
              linkedInProfile: { ...field, fieldValue: "https://www.linkedin.com/in/" }, pwd: "", cpwd: ""
            })
            let token = ress?.token
            sessionStorage.setItem("token", token)
            getUserDataname()

          }
          else {
            setPopupMsg(ress?.message)
            setErrPopup1(true)
          }
        })
    } catch (e) {
      console.log(e);
    }

  }

  const getUserDataname = () => {
    let token = sessionStorage.getItem("token");
      fetch(Url.getuser, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          Authorization: "Bearer " + token,

        }
      })
        .then((resp) => resp.json())
        .then((res) => {
          sessionStorage.setItem("userName", JSON.stringify(res?.DATA?.fullName))
          sessionStorage.setItem("userId",res?.DATA?.userId);
          setUserDetails({
            ...userDetails, loggedInRole:res?.DATA?.roles[0]?.name, isActive : res?.DATA?.licensed
          })
          let companyDetailsAdded = null
          if (res?.DATA?.company.length > 0) {
            companyDetailsAdded = true
          }
          else if (res?.DATA?.company.length == 0) {
            companyDetailsAdded = false
          }
          navigate("/dashboard", { state: { showAddCompanyPopup: companyDetailsAdded } });
        })
        .catch((e) => {
          console.log(e);
        })
  }

  const handleOtpVerify = () => {
    let obj = {
      messageAuditId: auditId?.messageAuditId,
      OTP: otp
    }
    if (otp?.length == 4) {
      if (auditId?.messageAuditId == "") {
        setPopupMsg("Please send the OTP to verify mobile number.")
        setErrPopup1(true)
      } else {
        try {
          fetch(Url.otpVerify, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
            },
            body: JSON.stringify(obj)
          }).then((resp) => resp.json())
            .then((res) => {
              console.log("otp verify response", res);
              if (res?.SUCCESS == true && res?.DATA == true) {
                setPopupMsg("Mobile No. OTP verified successfully.")
                setSuccessPopup1(true)
                setInvalidOTP({ ...invalidOTP, mno: false })
              } else {
                setPopupMsg(res?.message)
                setErrPopup1(true)
                setInvalidOTP({ ...invalidOTP, mno: true })
              }
            })
            .catch((e) => {
              console.log(e);
            })
        } catch (e) {
          console.log(e);
        }
      }
    }
  }
  const handleOtpVerifyForEmail = () => {
    let obj = {
      messageAuditId: auditId?.emailAuditId,
      OTP: otp2
    }
    if (otp2?.length == 4) {
      if (auditId?.messageAuditId == "") {
        setPopupMsg("Please send the OTP to verify email.")
        setErrPopup1(true)
      } else {
        try {
          fetch(Url.otpVerify, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
            },
            body: JSON.stringify(obj)
          }).then((resp) => resp.json())
            .then((res) => {
              console.log("otp verify response", res);
              if (res?.SUCCESS == true && res?.DATA == true) {
                setPopupMsg("Email OTP verified successfully.")
                setSuccessPopup1(true)
                setInvalidOTP({ ...invalidOTP, email: false })
              } else {
                setPopupMsg(res?.message)
                setErrPopup1(true)
                setInvalidOTP({ ...invalidOTP, email: true })
              }
            })
            .catch((e) => {
              console.log(e);

            })
        } catch (e) {
          console.log(e);
        }
      }
    }
  }

  return (
    <div className='RegOuterDiv'>
      <div className='RegImgDiv'>
        <div className='OverlayNew'>
          <div className='RegImgDivTxt'>
            <div className='HiringMore'>Make Hiring More</div>
            <div className='humanTxt'>HUMAN</div>
            <p>Welcome to QuikHire.ai – reshaping the job search experience by seamlessly connecting job seekers and recruiters through innovative processes and technology. Join us in transforming the way talent meets opportunity and navigating the dynamic world of job hunting with ease.</p>
          </div>
        </div>
      </div>
      <div className='RegFormDiv'>
        <div className='RegFormInnerDiv'>
          <div className='RegFixedContainer'>
            <div className='RegLogoDiv'>
              <img src={HireMeLogo} />
            </div>
            <div className='RegAccTxt'>Registration</div>
            <div className='RegCreateAcc'>
              <span>Create an account to recruit potential applicants</span>
              <span>for the job opening.</span>
            </div>
          </div>
          {pwdScreen == false ?
            <>
              <div className='RegScrollableContent'>
                <div className='RegFieldDiv'>
                  <label>Full Name<span style={{ color: "#BC0000", fontWeight: 400 }}>*</span></label>
                  <input type='text' value={newUserDetails?.fullName?.fieldValue} className='RegInput' placeholder='Enter your full name' onChange={(e) => onTextChange("Full Name", e?.target?.value)} />
                  <small className='RegErrMsg'>{newUserDetails?.fullName?.errorField}</small>
                </div>
                <div className='RegFieldDiv mtop' style={{ marginBottom: 0 }}>
                  <label>Mobile No<span style={{ color: "#BC0000", fontWeight: 400 }}>*</span></label>
                  <div className='RegMnoDiv'>
                    <select disabled value={newUserDetails?.cntryCode} onChange={(e) => handleRegInputChange(e, "cntryCode")}>
                      {
                        countryCodes.map((item) => {
                          return (
                            <option value={item?.countryCodeId}>+{item?.countryCode}</option>
                          )
                        })
                      }
                    </select>
                    <input inputmode="numeric" type='number' pattern="\d{1,10}" value={newUserDetails?.mno?.fieldValue} readOnly={!!userIDEditUser} onChange={(e) => onTextChange("Mobile Number", e?.target?.value)} placeholder='Enter your mobile number' />
                  </div>
                  <small className='RegErrMsg'>{newUserDetails?.mno?.errorField}</small>
                </div>
                <div className='RegChkBxDiv'>
                  <div className='RegChkBxSubDiv'>
                    <input type='checkbox' checked={newUserDetails?.mnoChkBx == true ? true : false} className='RegChkBx' onChange={(e) => handleRegInputChange(e, "mnoChkBx")} />
                  </div>
                  <div className='RegChkBxContent'>
                    By checking the check box, you agree to receive text messages from QuikHire.
                  </div>
                </div>
                {resendOtp?.mobile != true ?
                  <div className='RegResendOtpDiv'><span onClick={handleSendOtp}>Send OTP</span></div>
                  :
                  <div className='RegResendOtpDiv'><span onClick={handleSendOtp}>Resend OTP</span></div>
                }

                <div className='RegOtpDiv'>
                  <label>OTP<span style={{ color: "#BC0000", fontWeight: 400 }}>*</span></label>
                  <div className='RegOtpInput'>
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      renderSeparator={<span className='separatorWidth'></span>}
                      renderInput={(props) => <input {...props} />}
                      inputStyle="regOTPinputField"
                      inputType='number'
                    />
                  </div>
                </div>
                <div className='RegFieldDiv mtop' style={{ marginBottom: 0, marginTop: "3%" }}>
                  <label>Email Address<span style={{ color: "#BC0000", fontWeight: 400 }}>*</span></label>
                  <input type='email' inputmode="email" readOnly={!!userIDEditUser} value={newUserDetails?.email?.fieldValue} className='RegInput' placeholder='Enter your corporate email address only' onChange={(e) => onTextChange("Email Address", e?.target?.value)} />
                  <small className='RegErrMsg'>{newUserDetails?.email?.errorField}</small>
                </div>
                <div className='RegChkBxDiv'>
                  <div className='RegChkBxSubDiv'>
                    <input type='checkbox' checked={newUserDetails?.emailChkBx == true ? true : false} className='RegChkBx' onChange={(e) => handleRegInputChange(e, "emailChkBx")} />
                  </div>
                  <div className='RegChkBxContent'>
                    By checking the check box, you agree to receive emails from QuikHire.
                  </div>
                </div>
                {!hideEmailField && <>
                {resendOtp?.email != true ?
                  <div className='RegResendOtpDiv'><span onClick={handleEmailOtpSend}>Send OTP</span></div>
                  :
                  <div className='RegResendOtpDiv'><span onClick={handleEmailOtpSend}>Resend OTP</span></div>
                }

                <div className='RegOtpDiv'>
                  <label>OTP<span style={{ color: "#BC0000", fontWeight: 400 }}>*</span></label>
                  <div className='RegOtpInput'>
                    <OtpInput
                      value={otp2}
                      onChange={setOtp2}
                      numInputs={4}
                      renderSeparator={<span className='separatorWidth'></span>}
                      renderInput={(props) => <input {...props} />}
                      inputStyle="regOTPinputField"
                      inputType='number'
                    />
                  </div>
                </div></>}
                <div className='RegFieldDiv mtop' style={{ marginTop: "3.5%" }}>
                  <label>LinkedIn Profile<span style={{ color: "#BC0000", fontWeight: 400 }}>*</span></label>
                  <input type='text' value={newUserDetails?.linkedInProfile?.fieldValue} className='RegInput' placeholder='https://www.linkedin.com/.......' onChange={(e) => onTextChange("LinkedIn Profile", e?.target?.value)} />
                  <small className='RegErrMsg'>{newUserDetails?.linkedInProfile?.errorField}</small>
                </div>
                <div className='RegBtnDiv'>
                  <button onClick={handleNxtScreen}>Next</button>
                </div>
              </div>
              <div className='RegAlreadyAcc'>
                Already have an account? <span onClick={() => navigate('/login')}>Login</span>
              </div>
            </> :
            <div className='RegScrollableContent'>
              <div className='RegFieldDiv mtop' style={{ position: "relative" }}>
                <label>Password<span style={{ color: "#BC0000", fontWeight: 400 }}>*</span></label>
                <input type={hidePwd?.npwd == false ? "password" : "text"} value={newUserDetails?.pwd} className='RegInput' placeholder='Enter your password' onChange={(e) => handleRegInputChange(e, "pwd")} />
                <small className='RegErrMsg'>{errMsg?.pwd}</small>
                <img className={errMsg?.pwd == "" ? 'eyeIcon' : 'eyeIcon1'} src={hidePwd.npwd == true ? eyeIcon : hiddenEyeIcon} onClick={handleShowNpwd} />
              </div>
              <div className='RegFieldDiv mtop' style={{ position: "relative" }}>
                <label>Confirm Password<span style={{ color: "#BC0000", fontWeight: 400 }}>*</span></label>
                <input type={hidePwd?.cnpwd == false ? "password" : "text"} value={newUserDetails?.cpwd} className='RegInput' placeholder='Confirm your password' onChange={(e) => handleRegInputChange(e, "cpwd")} />
                <small className='RegErrMsg'>{errMsg?.cpwd}</small>
                <img className={errMsg?.cpwd == "" ? 'eyeIcon' : 'eyeIcon1'} src={hidePwd.cnpwd == true ? eyeIcon : hiddenEyeIcon} onClick={handleShowCnpwd} />
              </div>
              <div className='RegBtnDiv'>
                <button onClick={handleRegisterUser}><img src={Qregister} alt='Something went wrong' /></button>
              </div>
              {/* <div className='quikRegDiv' onClick={handleRegisterUser}>
                <img src={quik} alt='loading'/>
                <span>Register</span>
              </div> */}
              <div className='RegBtnDiv'>
                <button onClick={() => setPwdScreen(false)}>Back</button>
              </div>
            </div>
          }
        </div>
      </div>

      <Modal
        isOpen={errPopup1}
        onRequestClose={() => setErrPopup1(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={() => setErrPopup1(false)} className="delAccModalCrossPara">×</p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={Oops}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg">
          {popupMsg}
        </div>
        <div className="okBtnModal">
          <button onClick={() => { setErrPopup1(false) }}>OK</button>
        </div>
      </Modal>

      <Modal
        isOpen={successPopup}
        onRequestClose={() => setSuccessPopup(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
        shouldCloseOnOverlayClick={false}
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={() => navigate('/login')} className="delAccModalCrossPara">×</p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={completed}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg" style={{lineHeight:"25px"}}>
          {popupMsg}
        </div>
        <div className="okBtnModal">
          <button onClick={handleAutoLogin}>OK</button>
        </div>
      </Modal>

      <Modal
        isOpen={successPopup1}
        onRequestClose={() => setSuccessPopup1(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={() => setSuccessPopup1(false)} className="delAccModalCrossPara">×</p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={completed}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg">
          {popupMsg}
        </div>
        <div className="okBtnModal">
          <button onClick={() => setSuccessPopup1(false)}>OK</button>
        </div>
      </Modal>
    </div>


  )
}

export default Register