import React, { useEffect, useState } from "react";
import "./LandingPage.css";
import { useNavigate } from "react-router-dom";
const LandingPage = () => {
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    token: "",
  });

  useEffect(() => {
    const getUserData = () => {
      let token = sessionStorage.getItem("token");
      let userrole = sessionStorage.getItem("role");
      let username = sessionStorage.getItem("user_name");
      setUserData({ ...userData, token: token });
      if (token?.length > 0) {
        // navigate('/joblist')
        return;
      } else {
        navigate("/login");
      }
    };
    getUserData();
    clearSession()
  
    
  }, []);

  const clearSession =()=>{
    sessionStorage.removeItem('TablePageNumber');
    sessionStorage.removeItem('searchText')
    sessionStorage.removeItem('filterOption');
    sessionStorage.removeItem('storedPageNumber');
  }
  return (
    <div className="dashboardMainContainerWrapper">
      <div className="dashboardMainContainer1">
        <div className="navigatingCards" onClick={()=>{navigate('/dashboard')}}><p>Dashboard</p></div>
        <div className="navigatingCards" onClick={()=>{navigate('/joblist')}}><p>Job Repository</p></div>
        <div className="navigatingCards" onClick={()=>{navigate('/createjobs')}}><p>Create Job</p></div>
        <div className="navigatingCards" onClick={()=>{navigate('/joblist')}}><p>Edit/Modify Job</p></div>
      </div>
      <div className="dashboardMainContainer2">
      <div className="navigatingCards" onClick={()=>{navigate('/applicantrepository')}}><p>Applicant Repository</p></div>
        <div className="navigatingCards" onClick={()=>{navigate('/profile')}}><p>Profile Page</p></div>
        <div className="navigatingCards" onClick={()=>{navigate('/notification')}}><p>Notification Page</p></div>
        {/* <div className="navigatingCards" onClick={()=>{navigate('/joblist')}}><p>Applied/Viewed</p></div> */}
      </div>
      {/* <div className="dashboardMainContainer3">
     
      </div> */}
    </div>
  );
};

export default LandingPage;
