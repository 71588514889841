import React, { useEffect, useState } from "react";
import "./InputTextBox.css";
const InputTextBox = ({
  label,
  isRequired,
  labelStyle = {},
  placeholderText = "",
  value = "",
  onTextChange,
  ParentFieldWrapper={},
  errormsg,
  paddingLeftZeroRequired,
  maxLength,
  inputType = "text"
}) => {
  

  return (
    <div style={{width:"100%"}}>
      {label ? (
        <label className="defaultLabelStyle" style={labelStyle}>
          {label}{isRequired ? (<span style={{ color: "#BC0000", fontWeight: "bold" }}>{"*"}</span>):(<></>)}
        </label>
      ) : (
        <></>
      )}
      <div className="searchboxWrapper" style={ParentFieldWrapper}>
          <input type={inputType} className="searchfield" maxLength={maxLength} style={{margin:"0 10px",width:"100%",paddingLeft:paddingLeftZeroRequired=="true"?0:""}} value={value}
          onChange={onTextChange}
          placeholder={placeholderText}/>
      </div>
      {errormsg && <div>
          <small className='errormsgStyle'>{errormsg}</small>
      </div>}
    </div>
  );
};

export default InputTextBox;
